import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

@Injectable({
  // NOTE : Has to be provided in root so global loading sessions are shared across all modules
  providedIn: 'root'
})
export class LoaderService implements OnDestroy {
  public isLoading$: Observable<boolean>;

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private globalLoadingSessions$ = new BehaviorSubject<Record<string, never>[]>([]);
  private onDestroySubject$ = new Subject<void>();

  constructor() {
    this.isLoading$ = this._isLoading$.asObservable();

    this.globalLoadingSessions$
      .pipe(
        map(globalLoadingSessions => !!globalLoadingSessions.length),
        takeUntil(this.onDestroySubject$)
      )
      .subscribe(this._isLoading$);
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  /**
   * Wrap an observable in another observable to associate a loading session to its execution
   * within the queue of loading sessions of the service
   *
   * @param observable The observable to follow in loader
   */
  public globalObservableLoadingSession<T>(observable: Observable<T>): Observable<T> {
    return this.observableLoadingSession(observable, this.globalLoadingSessions$);
  }

  /**
   * Wrap an observable in another observable to associate a loading session to its execution
   * within a given queue of loading sessions
   *
   * @param observable The observable to follow in loader
   * @param loadingSessions$ The loading sessions of the loader
   */
  public observableLoadingSession<T>(
    observable: Observable<T>,
    loadingSessions$: BehaviorSubject<Record<string, never>[]>
  ): Observable<T> {
    return defer(() => {
      this.addLoadingSession(loadingSessions$);

      return observable.pipe(
        finalize(() => {
          this.removeLoadingSession(loadingSessions$);
        })
      );
    });
  }

  /**
   * Wrap an observable in another observable to associate a loading boolean to its execution
   *
   * @param observable The observable to follow in loader
   * @param isLoading$ The display boolean of the loader
   */
  public loadingObservable<T>(
    observable: Observable<T>,
    isLoading$: BehaviorSubject<boolean>
  ): Observable<T> {
    return defer(() => {
      isLoading$.next(true);

      return observable.pipe(
        finalize(() => {
          isLoading$.next(false);
        })
      );
    });
  }

  private addLoadingSession(loadingSessions$: BehaviorSubject<Record<string, never>[]>): void {
    loadingSessions$.next([...loadingSessions$.value, {}]);
  }

  private removeLoadingSession(loadingSessions$: BehaviorSubject<Record<string, never>[]>): void {
    loadingSessions$.next(loadingSessions$.value.slice(1));
  }
}
