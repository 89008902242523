import { Type } from '@models/lead/lead';
import { ApiLeadContact, LeadContact } from '@models/lead/lead-contact';
import { User } from '@models/user/user';

/**
 * Represents a lead who contacted a school for a specific application
 * No uniqueness on this case // FIXME: maybe it's a problem
 */
export class LeadContactSchool extends LeadContact {
  /**
   * @param id The lead id
   * @param application The application on which the lead is linked
   * @param email The user email
   * @param user The user
   * @param type The type of the lead
   * @param waypointUuid The waypoint's uuid at which the user is interested
   * @param instanceId The Instance's id to retrieve the context of the adventure
   * @param schoolUuid The contacted school's uuid
   * @param campusUuid The contacted campus's uuid
   * @param offerUuid The contacted offer's uuid
   * @param contactMail Offer's, Campus's or School's mail
   * @param subject The subject of the email to send
   * @param body The body of the email to send
   * @param url The url of the adventure
   */
  constructor(
    public id: string,
    public application: string,
    public email: string,
    public user: User,
    public type: Type,
    public waypointUuid: string,
    public instanceId: string,
    public schoolUuid: string,
    public campusUuid: string,
    public offerUuid: string,
    public contactMail: string,
    public subject: string,
    public body: string,
    public url: string
  ) {
    super(id, application, email, user, type, waypointUuid, instanceId);
  }

  public static toLeadContactSchool(
    application: string,
    email: string,
    user: User,
    waypointUuid: string,
    instanceId: string,
    schoolUuid: string,
    campusUuid: string,
    subject: string,
    body: string,
    url: string
  ): LeadContactSchool {
    return new LeadContactSchool(
      null,
      application,
      email,
      user,
      Type.CONTACT_SCHOOL,
      waypointUuid,
      instanceId,
      schoolUuid,
      campusUuid,
      null,
      null,
      subject,
      body,
      url
    );
  }

  /**
   * Build the Lead Contact object from the Api Lead Contact object
   *
   * @param apiLeadContactSchool The Api object Lead Contact School
   * @returns The new Lead Contact School object
   */
  public static build(apiLeadContactSchool: ApiLeadContactSchool): LeadContactSchool {
    if (!apiLeadContactSchool) return null;

    return new LeadContactSchool(
      apiLeadContactSchool.id,
      apiLeadContactSchool.application,
      apiLeadContactSchool.email,
      apiLeadContactSchool.apiUser ? User.build(apiLeadContactSchool.apiUser) : null,
      apiLeadContactSchool.type,
      apiLeadContactSchool.waypointUuid,
      apiLeadContactSchool.instanceId,
      apiLeadContactSchool.schoolUuid,
      apiLeadContactSchool.campusUuid,
      apiLeadContactSchool.offerUuid,
      apiLeadContactSchool.contactMail,
      apiLeadContactSchool.subject,
      apiLeadContactSchool.body,
      apiLeadContactSchool.url
    );
  }

  /**
   * Get the Api Lead Contact School object from the Lead Contact School object
   *
   * @returns The corresponding Api Lead Contact School object
   */
  public toApi(): ApiLeadContactSchool {
    return {
      id: this.id,
      application: this.application,
      email: this.email,
      apiUser: this.user?.toApi(),
      type: this.type,
      waypointUuid: this.waypointUuid,
      instanceId: this.instanceId,
      schoolUuid: this.schoolUuid,
      campusUuid: this.campusUuid,
      offerUuid: this.offerUuid,
      contactMail: this.contactMail,
      subject: this.subject,
      body: this.body,
      url: this.url
    } as ApiLeadContactSchool;
  }
}

export interface ApiLeadContactSchool extends ApiLeadContact {
  /**
   * The contacted school's uuid
   */
  schoolUuid: string;

  /**
   * The contacted campus's uuid
   */
  campusUuid: string;

  /**
   * The contacted offer's uuid
   */
  offerUuid: string;

  /**
   * Offer's, Campus's or School's mail
   */
  contactMail: string;

  /**
   * The subject of the email to send
   */
  subject: string;

  /**
   * The body of the email to send
   */
  body: string;

  /**
   * The url of the adventure
   */
  url: string;
}
