import { ApiLead, Lead, Type } from '@models/lead/lead';
import { User } from '@models/user/user';

/**
 * Represents a lead who generated a roadmap for a specific application
 * Only one exists for each application, email and instanceId
 */
export class LeadRoadmap extends Lead {
  /**
   * @param id The lead id
   * @param application The application on which the lead is linked
   * @param email The user email
   * @param user The user
   * @param type The type of the lead
   * @param instanceId The Instance's id to retrieve the context of the adventure
   * @param url The coupon code if the reward is of the coupon code type
   * @protected
   */
  constructor(
    public id: string,
    public application: string,
    public email: string,
    public user: User,
    public type: Type,
    public instanceId: string,
    public url: string
  ) {
    super(id, application, email, user, type);
  }

  public static toLeadRoadmap(
    application: string,
    email: string,
    instanceId: string,
    url: string
  ): LeadRoadmap {
    return new LeadRoadmap(null, application, email, null, Type.ROADMAP, instanceId, url);
  }

  /**
   * Build the Lead Roadmap object from the Api Lead Roadmap object
   *
   * @param apiLeadRoadmap The Api object Lead Roadmap
   * @returns The new Lead Roadmap object
   */
  public static build(apiLeadRoadmap: ApiLeadRoadmap): LeadRoadmap {
    if (!apiLeadRoadmap) return null;

    return new LeadRoadmap(
      apiLeadRoadmap.id,
      apiLeadRoadmap.application,
      apiLeadRoadmap.email,
      apiLeadRoadmap.apiUser ? User.build(apiLeadRoadmap.apiUser) : null,
      apiLeadRoadmap.type,
      apiLeadRoadmap.instanceId,
      apiLeadRoadmap.url
    );
  }

  /**
   * Get the Api Lead Roadmap object from the Lead Roadmap object
   *
   * @returns The corresponding Api Lead Roadmap object
   */
  public toApi(): ApiLeadRoadmap {
    return {
      id: this.id,
      application: this.application,
      email: this.email,
      apiUser: this.user?.toApi(),
      type: this.type,
      instanceId: this.instanceId,
      url: this.url
    } as ApiLeadRoadmap;
  }
}

export interface ApiLeadRoadmap extends ApiLead {
  /**
   * The Instance's id to retrieve the context of the adventure
   */
  instanceId: string;

  /**
   * The coupon code if the reward is of the coupon code type
   */
  url: string;
}
