/**
 * Gender as it is represented in Hroads API
 */
export enum ApiHroadsGender {
  Man = 'm',
  Lady = 'f',
  Androgynous = 'n',
  ManNotSure = 'm?',
  LadyNotSure = 'f?',
  AndrogynousNotSure = 'n?',
  Unspecified = '?'
}
