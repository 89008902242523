import { Cause } from '@features/causes/causes.models';
import { Campus } from '@models/campus/campus';
import { IMedia } from '@models/company/company';
import { Currency } from '@models/currency/currency';
import { Location } from '@models/location/location';
import { Offer } from '@models/offer/offer';
import { ApiSchool } from './school.api';

/**
 * School model which describe a school with its offers for a particular waypoint
 */
export class School extends Location {
  /**
   * If the school has sandwich trainings or not
   */
  public hasSandwichTraining = false;

  /**
   * The minimum cost of a school offers
   */
  public cost = Infinity;

  /**
   * The currency used for the minimum cost
   */
  public currency: Currency;

  public get uniqueOffers(): Offer[] {
    const offerLabels: string[] = this.offers.map(offer => offer.label);

    return this.offers.filter((offer, index) => offerLabels.indexOf(offer.label) === index);
  }

  public causes: Cause[];

  /**
   * School model which describe a school with its offers for a particular waypoint
   *
   * @param id The school id
   * @param name The school name
   * @param userCount The number of users that are graduated by this school
   * @param offers The school offers
   * @param campuses The school campuses
   * @param city The city where the school is
   * @param distance The distance of the school if filters are set
   * @param hasAcquisition Whether the school has an Acquisition GPS or not
   * @param application (Optional) The school application if the school is a Millionroads client
   * @param hasContactMail (Optional) If the school has a contact mail
   * @param causes (Optional) Causes of the school
   */
  constructor(
    id: string,
    name: string,
    userCount: number,
    public readonly offers: Offer[],
    campuses: Campus[],
    city: string,
    distance: number,
    media: IMedia,
    public readonly hasAcquisition: boolean = false,
    application?: string,
    causes?: Cause[],
    public readonly hasContactMail?: boolean
  ) {
    super(id, name, userCount, [], city, campuses, distance, media, application);

    this.hasSandwichTraining = this.offers.some(offer => offer.isSandwichTraining);

    this.offers.forEach(offer => {
      if (offer.cost !== null && offer.cost !== undefined && offer.cost < this.cost) {
        this.cost = offer.cost;
        this.currency = offer.formatCurrency;
      }
    });

    if (this.cost === Infinity) this.cost = null;
  }

  public static build(apiSchool: ApiSchool): School {
    return new School(
      apiSchool.id,
      apiSchool.name,
      apiSchool.userCount,
      apiSchool.offers.map(apiOffer => Offer.build(apiOffer)),
      apiSchool.campuses,
      apiSchool.city,
      apiSchool.distance,
      apiSchool.media,
      apiSchool.hasAcquisition,
      apiSchool.application,
      apiSchool.causes,
      apiSchool.hasContactMail
    );
  }
}
