import { ApiLead, Lead, Type } from '@models/lead/lead';
import { User } from '@models/user/user';

/**
 * Represents a lead who contacted the admissions service for a specific application
 * Only one exists for each application and email
 */
export class LeadContact extends Lead {
  /**
   * @param id The lead id
   * @param application The application on which the lead is linked
   * @param email The user email
   * @param user The user
   * @param type The type of the lead
   * @param waypointUuid The waypoint's uuid at which the user is interested
   * @param instanceId The Instance's uuid to retrieve the context of the adventure
   */
  constructor(
    public id: string,
    public application: string,
    public email: string,
    public user: User,
    public type: Type,
    public waypointUuid: string,
    public instanceId: string
  ) {
    super(id, application, email, user, type);
  }

  public static toLeadContact(
    application: string,
    email: string,
    waypointUuid: string,
    instanceId: string
  ): LeadContact {
    return new LeadContact(
      null,
      application,
      email,
      null,
      Type.CONTACT_ADMISSION,
      waypointUuid,
      instanceId
    );
  }

  /**
   * Build the Lead Contact object from the Api Lead Contact object
   *
   * @param apiLeadContact The Api object Lead Contact
   * @returns The new Lead Contact object
   */
  public static build(apiLeadContact: ApiLeadContact): LeadContact {
    if (!apiLeadContact) return null;

    return new LeadContact(
      apiLeadContact.id,
      apiLeadContact.application,
      apiLeadContact.email,
      apiLeadContact.apiUser ? User.build(apiLeadContact.apiUser) : null,
      apiLeadContact.type,
      apiLeadContact.waypointUuid,
      apiLeadContact.instanceId
    );
  }

  /**
   * Get the Api Lead Contact object from the Lead Contact object
   *
   * @returns The corresponding Api Lead Contact object
   */
  public toApi(): ApiLeadContact {
    return {
      id: this.id,
      application: this.application,
      email: this.email,
      apiUser: this.user?.toApi(),
      type: this.type,
      waypointUuid: this.waypointUuid,
      instanceId: this.instanceId
    } as ApiLeadContact;
  }
}

/**
 * A lead contact as it is represented on API
 */
export interface ApiLeadContact extends ApiLead {
  /**
   * The waypoint's uuid at which the user is interested
   */
  waypointUuid: string;

  /**
   * The Instance's uuid to retrieve the context of the adventure
   */
  instanceId: string;
}
