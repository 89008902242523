import { Injectable } from '@angular/core';
import { PaginedResponse } from '@core/dtos/pagined-response.dto';
import { ICompany, IHuman, Company, GmapDto } from '@models/company/company';
import { Filters } from '@models/filters/filters';
import { Observable } from 'rxjs';
import { CompanyClient } from '../clients';

@Injectable({ providedIn: 'root' })
export class CompanyService {

  constructor(private client: CompanyClient) {}

  public getCompany(id: string): Observable<ICompany> {

    return this.client.getCompany(id);
  }

  /**
   *
   * @param id uuid of the company
   * @param inMySchool 'include' to include people who have been through my school 'exlude' to exclude them, 'interns' to only get humans in internship
   * @returns IHuman list
   */
  public getHumansFromCompany(id: string, inMySchool?: { include?: boolean; exclude?: boolean; internship?: boolean }
  ): Observable<IHuman[]> {

    return this.client.getHumansFromCompany(id, inMySchool);
  }

  public searchCompanies(companiesFilters?: Filters): Observable<PaginedResponse<Company>> {

    return this.client.searchCompanies(companiesFilters);
  }

  public searchGmapCompanies(filters: Filters): Observable<Array<GmapDto>> {

    return this.client.searchGmapCompanies(filters);
  }
}
