import { IFilters } from '../filters.interface';

/**
 * Cursus filters
 */
export class SandwichFilters implements IFilters {
  public get activeFilters(): number {
    return +this.hasSandwichTrainings;
  }

  /**
   * Cursus filters
   *
   * @param hasSandwichTrainings Indicate if results need to have sandwich training options
   */
  constructor(public hasSandwichTrainings: boolean = false) {}

  public empty(): void {
    this.hasSandwichTrainings = false;
  }

  public copy(filter: SandwichFilters): void {
    this.hasSandwichTrainings = filter.hasSandwichTrainings;
  }
}
