import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationContext } from '@core/services';
import { Product } from '@models/context/product';
import { User } from '@models/user/user';
import { Observable } from 'rxjs';

const TRACKING_URI = '/apis/tracking';

interface Action {
  uuid: string;
  product: string;
  application: string;
  type: string;
}

/**
 * Client responsible of actions tracking
 */
@Injectable({ providedIn: 'root' })
export class TrackingClient {
  constructor(private http: HttpClient, private ctx: ApplicationContext) {}

  /**
   * Send tracking action to save
   *
   * @param user The connected user
   * @returns The action saved
   */
  public actions(user: User): Observable<Action> {
    const { applicationName, product } = this.ctx;

    const productTracked = product === Product.Connect ? 'CONNECT' : 'RECRUIT';

    return this.http.post<Action>(`${TRACKING_URI}/actions`, {
      uuid: user.uuid,
      product: productTracked,
      application: applicationName,
      type: 'LOGGED'
    });
  }
}
