import { Application } from '@clients/adventure/bean/application';
import { ApplicationName } from '@models/context/application-name';

/**
 * A config setting whether the schools are displayed in the application
 *
 * @param application The application
 * @returns Whether the schools are displayed in the application
 */
export const SCHOOLS_ENABLED_CONFIG = (application: Application): boolean => {
  switch (application.application) {
    case ApplicationName.MyRoad:
    case ApplicationName.PaysDeLaLoire:
    case ApplicationName.RegionSud:
    case ApplicationName.CmqBtpNumerique:
    case ApplicationName.Carto:
      return true;
    default:
      return false;
  }
};
