import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginedResponse } from '@core/dtos/pagined-response.dto';
import { Cause } from '@features/causes/causes.models';
import { Company, GmapDto, ICompany, IHuman } from '@models/company/company';
import { Filters } from '@models/filters/filters';
import { ApiLocation } from '@models/location/location.api';
import { HumanPoi, convertHumanPoiInHuman } from '@models/poi/human-poi/human-poi';
import { ApiHumanPoi } from '@models/poi/human-poi/human-poi.api';
import { environment } from 'environments/environment';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { ApplicationContext } from '../context';

@Injectable({ providedIn: 'root' })
export class CompanyClient {
  private readonly COMPANY_URI = `${environment.adventureUri}/companies`;

  constructor(private http: HttpClient, private applicationCtx: ApplicationContext) {}

  public getCompany(id: string): Observable<ICompany> {
    const company$ = this.http.get<ICompany>(`${this.COMPANY_URI}/${id}`);
    const causes$ = this.getCompanyCauses(id);

    return forkJoin([company$, causes$]).pipe(
      map(([company, causes]) => ({
        ...company,
        causes
      }))
    );
  }

  /**
   *
   * @param id uuid of the company
   * @param inMySchool 'include' to include people who have been through my school 'exlude' to exclude them, 'interns' to only get humans in internship
   * @returns IHuman list
   */
  public getHumansFromCompany(
    id: string,
    inMySchool?: { include?: boolean; exclude?: boolean; internship?: boolean }
  ): Observable<IHuman[]> {
    let body = {};

    if (inMySchool?.include) {
      body = {
        in_location_uuid: this.applicationCtx?.application?.nodeId,
        query: inMySchool.internship ? 'internship' : 'first-job'
      };
    }

    if (inMySchool?.exclude) {
      body = { exclude_location_uuid: this.applicationCtx?.application?.nodeId };
    }

    return this.http.post<ApiHumanPoi[]>(`${this.COMPANY_URI}/${id}/humans`, { ...body }).pipe(
      map(apiHumanPois => {
        if (!apiHumanPois) return [];

        return apiHumanPois.map((apiHumanPoi: ApiHumanPoi) =>
          convertHumanPoiInHuman(HumanPoi.build(apiHumanPoi))
        );
      })
    );
  }

  public searchCompanies(companiesFilters?: Filters): Observable<PaginedResponse<Company>> {
    return this.http
      .post<PaginedResponse<ApiLocation>>(
        `${this.COMPANY_URI}/search`,
        companiesFilters ? companiesFilters.toApi() : {}
      )
      .pipe(
        map(
          ({ skip, limit, total, items }) =>
            ({
              skip,
              limit,
              total,
              items: items.map(item => Company.build(item))
            } as PaginedResponse<Company>)
        ),
        catchError(() => of(null))
      );
  }

  public searchGmapCompanies(filters: Filters): Observable<Array<GmapDto>> {
    return this.http
      .post<Array<GmapDto>>(`${this.COMPANY_URI}/geolocation`, filters.toApi())
      .pipe(catchError(() => of(null)));
  }

  private getCompanyCauses(id: string): Observable<Cause[]> {
    return this.http.get<Cause[]>(`${this.COMPANY_URI}/${id}/causes/thematic-memberships`);
  }
}
