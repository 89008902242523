import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { PopupApi } from '@models/popup-api/popup-api';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PopupApiClient {
  private readonly POPUP_API_URL =
    'https://neomapopup-humanroads-sandbox.azurewebsites.net/api/hr/navigation-result?code=5rXoWK97lpB299Z8f89OMiJzdhnUPgK6y%2FAaRcrxgm4UC797DqW3NA%3D%3D';

  constructor(private http: HttpClient) {}

  /**
   * Send user information to popup external API
   *
   * @param userId The external id of the user
   * @param waypoint The last waypoint of the user
   */
  public sendInformation(userId: string, waypoint: Waypoint): Observable<void> {
    return this.http.post<void>(
      `${this.POPUP_API_URL}`,
      {
        userId,
        label: waypoint.label,
        type: waypoint.type,
        code: waypoint.codeRome
      } as PopupApi,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        })
      }
    );
  }
}
