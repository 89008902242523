export enum PoiType {
  Chat = 'Chat',
  Diploma = 'Diploma',
  Event = 'Event',
  Course = 'Course',
  International = 'International',
  InternshipJob = 'InternshipJob',
  Mooc = 'Mooc',
  Offer = 'Offer',
  ParallelStudy = 'ParallelStudy',
  Parcoursup = 'Parcoursup',
  SandwichTrainingJob = 'SandwichTrainingJob',
  SelectionOnApplication = 'SelectionOnApplication'
}
