// eslint-disable-next-line max-classes-per-file
export interface KpiResult {
  count_results: number;
  count_users: number;
  results: KpiItem[];
  name: string;
}

export interface KpiItem {
  nb_resumes: number;
  count_u: number;
  count_results: number;
  name: string;
  uuid: string;
  kpiName: string;
}

export class KpiObject {
  /**
   * An object representing the configuration of a KPI
   *
   * @param id of the kpi (see hroads for the available KPIs)
   * @param limit of elements to requests and show
   * @param icon associated to the KPI
   * @param isList: trigger for List view
   * @param body: Hash of params to send the API
   */
  constructor(
    public id: string,
    public limit: number,
    public icon?: string,
    public isList?: boolean,
    public body?: KpiParams
  ) {}
}

/**
 * Params to send the Hroads API
 */
export interface KpiParams {
  /**
   * Specify a custom order for the elements
   */
  order_by?: string;
  /**
   * Filters for the kpi query. See Hroads service for complete list
   * To be completed with only needed filters
   */
  filters?: {
    application?: string;
    limit_r_threshold?: number;
    exclude_start_n?: boolean;
    exclude_nomenclatures?: string[];
    optional_nomenclatures?: boolean;
    start_n_type?: string;
    need_contains_analytics?: string;
    years_range?: string;
    cross?: { 0: { query: string; value: string; is_children: boolean } };
    exclude?: {
      words?: string[];
    };
    locale?: string;
  };

  node?: string;
  limit?: number;

  /**
   * Specify a custom order for the elements which is forces
   */
  force_order_by?: string;
}

export class Kpi {
  constructor(
    public id: string,
    public items: KpiItem[],
    public name?: string,
    public icon?: string,
    public isList?: boolean
  ) {}
}
