/**
 * An authenticated user group
 */
export enum Group {
  MyRoadBasic = 'module_myroad_basic',
  MyRoadAdvanced = 'module_myroad_advanced',
  Acquisition = 'module_acquisition',
  // FIXME : Remove and update tests when other groups are included
  TestGroup = 'module_test'
}
