export enum RouteIds {
  ADVENTURE_MAP,
  ADVENTURE_ROADMAP,
  EXPEDITION_STEP,
  EXPEDITION_STEP_STATIC,
  ERROR,
  EXPEDITION_DETAILS,
  EXPEDITION_INSTANCE_DETAILS,
  HOME,
  INTENTIONS_DIPLOMA_MAJOR_SELECT,
  INTENTIONS_WAYPOINT_DEFINITIONS,
  INTENTIONS_WAYPOINT_DETAILS,
  INTENTIONS_WAYPOINT_SEARCH,
  INTENTIONS_WAYPOINT,
  INTENTIONS,
  PROFILE,
  ROADMAPS,
  SCHOOL,
  SITUATION,
  EXPEDITION_LIBRARY,
  EXPEDITION,
  ADMIN,
  ADMIN_APPLICATION,
  ADMIN_CONTACTS,
  ADMIN_DEFINITIONS,
  ADMIN_PERFORMANCE,
  ADMIN_POIS,
  SETTINGS,
  AMBASSADORS
}
