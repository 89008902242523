import { Injectable } from '@angular/core';
import { isJob, Waypoint } from '@clients/adventure/bean/waypoint';
import { KPIS_SCOPE_CONFIG } from '@configs/kpis-scope.config';
import { SCHOOLS_ENABLED_CONFIG } from '@configs/schools-enabled.config';
import { ApplicationContext, HroadsClient } from '@core/services';
import { Area } from '@models/area/area';
import { Kpi, KpiItem, KpiObject, KpiParams } from '@models/kpi.model';
import { ApiHroadsUser } from '@models/user/hroads-user.api';
import { User } from '@models/user/user';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const KPI_LIMIT = 3;

@Injectable({ providedIn: 'root' })
export class HroadsService {
  // LIMIT
  public static readonly SKILLS_LIMIT = 6;

  // KPIS
  public static readonly LAST_STUDY_BEFORE = new KpiObject(
    'last-study-before',
    KPI_LIMIT,
    null,
    false,
    { filters: { exclude_start_n: true } }
  );

  public static readonly FIRST_COMPANY_SIZE = new KpiObject(
    'first-company-size',
    KPI_LIMIT,
    null,
    false,
    { order_by: 'popularity', force_order_by: 'nb_resumes_desc' }
  );

  public static readonly JOB_COMPANY = new KpiObject('job-company', KPI_LIMIT);

  public static readonly COMPANY_DOMAIN = new KpiObject('company-domain', KPI_LIMIT);

  private readonly COMPANIES_LIMIT = 5;

  private readonly SKILLS = new KpiObject('skills', HroadsService.SKILLS_LIMIT, '💪', true);

  private readonly INTERNSHIP_SANDWICH_TRAINING = new KpiObject(
    'internship-sandwich-training',
    KPI_LIMIT,
    '💼',
    false,
    { filters: { exclude_nomenclatures: ['job-stagiaire', 'job-apprenti', 'job-saisonnier'] } }
  );

  private readonly INTERNSHIP_SANDWICH_TRAINING_LOCATION_NAME = new KpiObject(
    'internship-sandwich-training-location',
    this.COMPANIES_LIMIT
  );

  private readonly DIPLOMA_SCHOOL = new KpiObject('diploma-school', KPI_LIMIT, '🎓');

  private readonly DIPLOMA_KPIS: KpiObject[] = [
    HroadsService.LAST_STUDY_BEFORE,
    this.SKILLS,
    this.INTERNSHIP_SANDWICH_TRAINING,
    this.INTERNSHIP_SANDWICH_TRAINING_LOCATION_NAME
  ];

  private readonly JOB_KPIS: KpiObject[] = [
    HroadsService.LAST_STUDY_BEFORE,
    this.SKILLS,
    HroadsService.FIRST_COMPANY_SIZE
  ];

  constructor(private client: HroadsClient, private context: ApplicationContext) {}

  /**
   * Last Study Before KPI with limit 1
   */
  public lastStudyBefore(waypoint: Waypoint): Observable<KpiItem[]> {
    return this.kpi(waypoint.id, 'last-study-before', 1);
  }

  /**
   * KPIs for a waypoint depending on its type
   */
  public kpis(waypoint: Waypoint): Observable<Kpi[]> {
    const KPIS = isJob(waypoint.type) ? this.JOB_KPIS : this.getDiplomaKpis();

    return forkJoin(
      KPIS.map(kpi =>
        this.kpi(waypoint.id, kpi.id, kpi.limit, kpi.body).pipe(
          map(kpiItem => new Kpi(kpi.id, kpiItem, null, kpi.icon, kpi.isList))
        )
      )
    ).pipe(map(kpis => kpis.filter(kpi => kpi.items.length)));
  }

  public kpi(
    waypointId: string,
    kpiName: string,
    limit: number,
    body?: KpiParams,
    withApp = false
  ): Observable<KpiItem[]> {
    return this.client.kpi(
      waypointId,
      kpiName,
      limit,
      withApp ? KPIS_SCOPE_CONFIG(this.context.application) : null,
      body
    );
  }

  /**
   * Search area names matching a search text
   *
   * @param searchText The search text
   * @param limit The result limit number
   * @returns An array of area names
   */
  public searchArea(searchText: string, limit?: number): Observable<Area[]> {
    return this.client.searchArea(searchText, limit || 10);
  }

  /**
   * Get additional information of a user from the Hroads API
   *
   * @param user The user
   * @returns An observable emitting the user from the Hroads API
   */
  public getUser(user: User): Observable<ApiHroadsUser> {
    return this.client.getUser(user);
  }

  private getDiplomaKpis(): KpiObject[] {
    if (SCHOOLS_ENABLED_CONFIG(this.context.application))
      return this.DIPLOMA_KPIS.concat(this.DIPLOMA_SCHOOL);

    return this.DIPLOMA_KPIS;
  }
}
