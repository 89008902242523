import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChoiceDiplomaType, Waypoint } from '@clients/adventure/bean/waypoint';
import { ItineraryItem } from '@core/dtos/itinerary.dto';
import { PaginedResponse } from '@core/dtos/pagined-response.dto';
import {
  FurtherWaypointsResponse,
  ReachabilityWaypointsResponse,
  RequiredWaypointsResponse
} from '@core/dtos/waypoint.dto';
import { APPLICATION_URI, ApplicationContext, SituationContext } from '@core/services';
import { Company } from '@models/company/company';
import { ApplicationName } from '@models/context/application-name';
import { Filters } from '@models/filters/filters';
import { ApiFilters } from '@models/filters/filters.api';
import { Gender } from '@models/gender/gender';
import { ItineraryGroup } from '@models/itinerary-group/itinerary-group';
import { ApiItineraryGroup } from '@models/itinerary-group/itinerary-group.api';
import { ApiLocation } from '@models/location/location.api';
import { Offer } from '@models/offer/offer';
import { ApiOffer } from '@models/offer/offer.api';
import { HumanPoi } from '@models/poi/human-poi/human-poi';
import { ApiHumanPoi } from '@models/poi/human-poi/human-poi.api';
import { School } from '@models/school/school';
import { ApiSchool } from '@models/school/school.api';
import { Structure } from '@models/structure/structure';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { Regions } from '@organisms/localisation-filter-dialog/regions';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

export interface BridgesAPI {
  'bridge-during': ChoiceDiplomaType;
  'bridge-after': ChoiceDiplomaType;
}

export interface Bridges {
  bridgeDuring: ChoiceDiplomaType;
  bridgeAfter: ChoiceDiplomaType;
}

export interface WaypointConcept {
  uuid: string;
  description: string;
  videoUrl: string;
}

@Injectable({ providedIn: 'root' })
export class WaypointClient {
  private readonly WAYPOINT_URI = `${environment.adventureUri}/waypoints`;

  constructor(
    private context: ApplicationContext,
    private http: HttpClient,
    private situationContext: SituationContext
  ) {}

  /**
   * Get a waypoint from an id
   *
   * @param waypointId The waypoint id
   * @param gender The user gender
   * @param regions
   * @param applicationNodeId
   * @param gender The region used for trends
   * @returns The matching waypoint
   */
  public waypoint(
    waypointId: string,
    gender: Gender,
    regions?: Regions,
    applicationNodeId?: string
  ): Observable<Waypoint> {
    let params = new HttpParams();

    if (gender) params = params.set('gender', gender);

    if (regions) params = params.set('trendRegion', regions);

    if (applicationNodeId) params = params.set('applicationUuid', applicationNodeId);

    return this.http.get<Waypoint>(`${this.WAYPOINT_URI}/${waypointId}`, { params });
  }

  /* -- SCHOOL -- */

  /**
   * Retrieves schools associated to a given waypoint within a given application, optionally using
   * filters
   *
   * @param waypointId The waypoint Id
   * @param applicationNodeUuid The application node id
   * @param waypointType The type of the waypoint
   * @param filters Optional filters
   * @param limit The number of results allowed
   * @returns The matching schools
   */
  public searchSchools(
    waypointId: string,
    applicationNodeUuid: string,
    waypointType: WaypointType,
    filters?: Filters
  ): Observable<PaginedResponse<School>> {
    const params = new HttpParams()
      .set('applicationUuid', applicationNodeUuid)
      .set('type', waypointType);

    let body: ApiFilters = {};

    if (filters) {
      body = { ...filters.toApi(), ...body };
    }

    return this.http
      .post<PaginedResponse<ApiSchool>>(`${this.WAYPOINT_URI}/${waypointId}/schools/search`, body, {
        params
      })
      .pipe(
        map(
          ({ skip, limit, total, items }) =>
            ({
              skip,
              limit,
              total,
              items: items.map(item => School.build(item)) // APISchool to School
            } as PaginedResponse<School>)
        )
      );
  }

  /**
   * Retrieve a school associated to a given waypoint
   *
   * @param waypointId The waypoint Id
   * @param applicationNodeId The application node id
   * @param schoolId The school Id
   * @returns The matching schools
   */
  public getSchool(
    waypointId: string,
    applicationNodeId: string,
    schoolId: string
  ): Observable<School> {
    const params = new HttpParams().set('appUuid', applicationNodeId);

    return this.http
      .get<ApiSchool>(`${this.WAYPOINT_URI}/${waypointId}/schools/${schoolId}`, { params })
      .pipe(map(apiSchool => School.build(apiSchool)));
  }

  /* -- STRUCTURE -- */

  /**
   * Retrieves structures within a given application, optionally using
   * filters
   *
   * @param applicationNodeId The application node id
   * @param structureFilters Optional filters
   * @param limit The number of results allowed
   * @returns The matching structures
   */
  public structures(
    applicationNodeId: string,
    structureFilters?: Filters,
    limit?: number
  ): Observable<Structure[]> {
    let params = new HttpParams();

    if (limit) params = params.set('limit', `${limit}`);

    return this.http.post<Structure[]>(
      `${APPLICATION_URI}/${applicationNodeId}/support-structures`,
      structureFilters ? structureFilters.toApi() : {},
      { params }
    );
  }

  /* -- ORIGINS -- */

  /**
   * Get origin waypoints for a destination and a user gender
   *
   * @param destinationId The destination Id
   * @param originFilters Optional filters
   * @returns An observable of the waypoints matching the destination and the user status
   */
  public getOrigins(destinationId: string, originFilters?: Filters): Observable<Waypoint[]> {
    let params = new HttpParams().set('a', this.context.applicationName).set('id', destinationId);

    if (this.situationContext.situation?.gender)
      params = params.set('gender', this.situationContext.situation.gender);

    return this.http.post<Waypoint[]>(
      `${this.WAYPOINT_URI}/origins`,
      originFilters ? originFilters.toApi() : {},
      { params }
    );
  }

  /* -- OFFER -- */

  /**
   * Retrieve offers for an application
   */
  public offers(nodeId: string): Observable<Offer[]> {
    return this.http
      .get<ApiOffer[]>(`${APPLICATION_URI}/${nodeId}/offers`)
      .pipe(map(apiOffers => apiOffers.map(apiOffer => Offer.build(apiOffer))));
  }

  /* -- RESUME NODE -- */

  /**
   * Find resume nodes (jobs or diplomas) matching a given search text based on the user status
   *
   * @param applicationNodeId The application node Id
   * @param resumeNodeType The resume node type (or waypoint type). Can be Job or Diploma
   * @param searchText The search text
   * @param filters The filters containing the region used to get the job trends
   */
  public searchResumeNodes(
    applicationNodeId: string,
    resumeNodeType: WaypointType,
    searchText: string,
    filters: Filters
  ): Observable<Waypoint[]> {
    let params = new HttpParams().set('type', resumeNodeType);

    if (searchText) {
      params = params.set('search', searchText);
    } else {
      params = params.set('limit', 100);
    }

    if (this.situationContext.situation)
      params = params
        .set('status', this.situationContext.situation.status)
        .set('gender', this.situationContext.situation.gender);

    return this.http.post<Waypoint[]>(
      `${APPLICATION_URI}/${applicationNodeId}/resumeNodes`,
      filters ? filters.toApi() : {},
      {
        params
      }
    );
  }

  /**
   * Find companies matching a given search text
   *
   * @param applicationNodeId The application node Id
   * @param searchText The search text
   * @param companyFilters The company search filters
   */
  public searchCompaniesByText(
    applicationNodeId: string,
    searchText: string,
    companyFilters: Filters
  ): Observable<Company[]> {
    const params = new HttpParams().set('search', searchText);

    return this.http
      .post<ApiLocation[]>(
        `${APPLICATION_URI}/${applicationNodeId}/companies`,
        companyFilters ? companyFilters.toApi() : {},
        {
          params
        }
      )
      .pipe(map(apiCompanies => apiCompanies.map(apiCompany => Company.build(apiCompany))));
  }

  /* -- COMPANY -- */

  /**
   * Retrieves companies associated to a given waypoint within a given application, optionally using
   * filters
   *
   * @param waypointId The waypoint Id
   * @param applicationNodeId The application node id
   * @param waypointType The type of the waypoint
   * @param filters Optional filters
   * @param limit The number of results allowed
   * @returns The matching companies
   */
  public searchCompanies(
    waypointId: string,
    applicationNodeId: string,
    waypointType: WaypointType,
    filters?: Filters
  ): Observable<PaginedResponse<Company>> {
    const { application, applicationName } = this.context;

    const params = new HttpParams()
      .set('applicationUuid', applicationNodeId)
      .set('type', waypointType);

    let body: ApiFilters = {
      applicationUuid:
        applicationName === ApplicationName.CmqBtpNumerique ? application.nodeId : undefined
    };

    if (filters) {
      body = { ...filters.toApi(), ...body };
    }

    return this.http
      .post<PaginedResponse<ApiLocation>>(
        `${this.WAYPOINT_URI}/${waypointId}/companies/search`,
        body,
        { params }
      )
      .pipe(
        map(
          ({ skip, limit, total, items }) =>
            ({
              skip,
              limit,
              total,
              items: items.map(item => Company.build(item)) // APICompany to Company
            } as PaginedResponse<Company>)
        )
      );
  }

  /* -- ITINERARIES -- */

  /**
   * Get itineraries for a destination with filters and a user gender
   *
   * @param destinationId The destination Id
   * @param itinerariesFilters Optional filters
   * @returns An observable of the itineraries matching the destination and the filters
   */
  public getItineraries(
    destinationId: string,
    itinerariesFilters?: Filters
  ): Observable<ItineraryGroup[]> {
    const params = new HttpParams().set('gender', this.situationContext.situation.gender);

    return this.http
      .post<PaginedResponse<ItineraryItem>>(`${this.WAYPOINT_URI}/itinerary/${destinationId}`, itinerariesFilters.toApi(), { params })
      .pipe(
        map(response =>
          response.items
            .filter(item => item.availablePathes) // TODO: Fix the backend, some items are equal {}
            .filter(item =>
              item.availablePathes.length > 0 && item.availablePathes[0].length >= 2)
            .map(item => ({
              label: item.availablePathes[0][item.availablePathes[0].length - 2].label,
              itineraries: item.availablePathes
            }))
        )
      );
  }


  /* -- HUMAN -- */

  /**
   * Get Humans POIs for a waypoint
   *
   * @param waypointId The waypoint ID
   * @returns An array of HumanPoi objects built from the array of HumanPoiResponse received from
   *          Adventure Service
   */
  public getHumanPois(waypointId: string, humansFilters?: Filters): Observable<HumanPoi[]> {
    let body: ApiFilters = {
      withWaypoints: true,
      isGenderFromUser: false
    };

    if (humansFilters) {
      body = { ...humansFilters.toApi(), ...body };
    }

    return this.http.post<ApiHumanPoi[]>(`${this.WAYPOINT_URI}/${waypointId}/humans`, body).pipe(
      map((apiHumanPois: ApiHumanPoi[]) => {
        if (!apiHumanPois) return [];

        return apiHumanPois.map((apiHumanPoi: ApiHumanPoi) => HumanPoi.build(apiHumanPoi));
      }),
      catchError(() => {
        throw new Error(`Could not find human Pois for ${waypointId}`);
      }),
      share()
    );
  }

  /* -- BRIDGES -- */

  /**
   * Get bridges for a waypoint
   *
   * @param id The waypoint ID
   * @param instanceId The instance ID
   * @param stage The waypoint stage
   * @returns An object containing the two bridges
   */
  public bridges(id: string, instanceId: string, stage: number): Observable<Bridges> {
    return this.http
      .post<BridgesAPI>(`${this.WAYPOINT_URI}/${id}/bridges`, { instanceId, stage })
      .pipe(
        map(bridges => ({
          bridgeDuring: bridges['bridge-during'],
          bridgeAfter: bridges['bridge-after']
        }))
      );
  }

  public getWaypointConcept(
    waypointId: string,
    nomenclature: WaypointType
  ): Observable<WaypointConcept> {
    const params: HttpParams = new HttpParams().set('nomenclature', nomenclature);

    return this.http.get<WaypointConcept>(`${this.WAYPOINT_URI}/${waypointId}/concept`, {
      params
    });
  }

  /* -- FUTHER -- */

  /**
   * Get further waypoints
   *
   * @param waypointUuid The origin waypoint ID
   * @param originUuid Optional: The origin id, to receive only recheable results
   * @param limit Optional: The items limit for retreived jobs and diplomas
   * @returns The diplomas and jobs to go further after this waypoint
   */
  public getFurther(
    waypointUuid: string,
    originUuid = '',
    limit = 10
  ): Observable<FurtherWaypointsResponse> {

    const params = new HttpParams()
      .set('originUuid', originUuid)
      .set('limit', limit);

    return this.http.get<FurtherWaypointsResponse>(`${this.WAYPOINT_URI}/${waypointUuid}/further`, { params });
  }

  /* -- REACHABILITY -- */

  /**
   * Get the reachability between to waypoints
   *
   * @param fromUUid The departure waypoint uuid
   * @param toUUid The destination waypoint uuid
   * @returns The reachability between these two waypoints
   */
  public getReachability(
    fromUuid: string,
    toUuuid: string
  ): Observable<ReachabilityWaypointsResponse> {
    return this.http.get<ReachabilityWaypointsResponse>(
      `${this.WAYPOINT_URI}/${fromUuid}/reachables/${toUuuid}`
    );
  }

  /**
   * Get required diplomas to have bjust before the waypoint
   *
   * @param toUUid The destination waypoint uuid
   * @param appUuid The id of the app
   * @param originUuid Optional: The origin id, to receive only recheable results
   * @param limit Optional: The items limit for retreived jobs and diplomas
   * @returns A list of diplomas
   */
  public getRequiredDiplomas(
    toUuuid: string,
    appUuid: string,
    originUuid = '',
    limit = 10
  ): Observable<RequiredWaypointsResponse> {

    const params = new HttpParams()
      .set('appUuid', appUuid)
      .set('originUuid', originUuid)
      .set('limit', limit);

    return this.http.get<RequiredWaypointsResponse>(
      `${this.WAYPOINT_URI}/${toUuuid}/required-diplomas`,
      { params }
    );
  }
}
