import { Situation } from '@models/situation/situation';
import { IFilters } from '../filters.interface';

/**
 * Cost filters
 */
export class SituationFilters implements IFilters {
  public get activeFilters(): number {
    return +this.useSituation;
  }

  /**
   * Situation filters
   *
   * @param situation The situation of the user
   * @param useSituation Indicates whether this situation has an impact on results
   */
  constructor(public situation: Situation = null, public useSituation = false) {}

  public empty(): void {
    this.useSituation = false;
  }

  public copy(filter: SituationFilters): void {
    this.useSituation = filter.useSituation;
    this.situation = filter.situation;
  }
}
