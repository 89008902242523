import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingClient } from '@clients/tracking/tracking.client';
import {
  REDIRECT_URI_CONFIG,
  SILENT_REFRESH_REDIRECT_URI_CONFIG
} from '@configs/redirect-uri.config';
import { SAVE_USER_CONTEXT_CONFIG } from '@configs/save-user-context.config';
import { authStorageFactory } from '@core/modules/auth/auth-storage';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { ApplicationContext, HroadsClient, SituationContext } from '@core/services';
import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';
import { ErrorCodes } from '@models/error-codes/error-codes';
import { IdentityClaims } from '@models/identity-claims/identity-claims';
import { User } from '@models/user/user';
import { Store } from '@ngrx/store';
import {
  NullValidationHandler,
  OAuthEvent,
  OAuthService,
  OAuthStorage,
  UserInfo
} from 'angular-oauth2-oidc';
import { selectIsAuthWithSSO } from 'app/store/settings/settings.selectors';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  /**
   * Used for authentication guards
   * Waits for the service to finish loading and check that the user is authenticated
   *
   * Waiting for the service loading is required to allow direct access to a protected
   * authenticated. This prevents the following situation:
   * - The user access the protected page: isAuthenticated is false as the initial tryLogin has not
   *    been done
   * - So, the application redirects to login page
   * - Back to the application after login, isAuthenticated is true (token_received event)
   * - The successful login callback redirects to the protected page and removes it from local
   *    storage
   * - Meanwhile, the service is still initializing (restarted when coming back to the application
   *    after login). When it loads the access token, it executes the successful login callback
   * - There is no redirectUri in local storage so it redirects to the home page while the user
   *    wanted to access the protected page
   *
   * Inspired from https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/
   */
  public canActivate$: Observable<boolean> = of(false);

  /**
   * An observable emitting whether the user is authenticated or not
   * It has to be directly used when the component or service has to be notified when the
   * authentication state changes. Otherwise, use the getter isAuthenticated
   */
  public readonly isAuthenticated$: Observable<boolean>;

  /**
   * An observable emitting the authenticated user
   * It has to be directly used when the component or service has to be notified when the
   * user changes. Otherwise, use the getter user
   */
  public readonly user$: Observable<User>;

  /**
   * Whether the user is authenticated or not
   */
  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The authenticated user
   */
  private _user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private readonly AUTH_STORAGE_ITEMS: Array<string> = [
    'access_token',
    'access_token_stored_at',
    'expires_at',
    'granted_scopes',
    'id_token',
    'id_token_claims_obj',
    'id_token_expires_at',
    'id_token_stored_at',
    'nonce',
    'PKCE_verifier',
    'refresh_token',
    'session_state'
  ];

  private onDestroySubject$: Subject<void> = new Subject<void>();

  /**
   * Indicates if the Authentication Service is fully loaded or not
   */
  private isDoneLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private saveUser: boolean;

  public get userSessionStorage(): User {
    if (this.isAuthenticated && this.user) return this.user;

    const userSessionStorageJSON = sessionStorage.getItem('user');

    if (!userSessionStorageJSON) return null;

    return User.buildFromSessionStorage(JSON.parse(userSessionStorageJSON));
  }

  public set userSessionStorage(user: User) {
    if (this.saveUser) sessionStorage.setItem('user', JSON.stringify(user.toUserSessionStorage()));
  }

  public get clientId(): string {
    return this.oauthService.clientId;
  }

  public get redirectUri(): string {
    return this.oauthService.redirectUri;
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated$.value;
  }

  public get user(): User {
    return this._user$.value;
  }

  /**
   * Check if we have the email of the user
   */
  public get hasEmail(): boolean {
    return !!this.email;
  }

  /**
   * Return the email of the current user
   * If it's authenticated, return the email of the authenticated user
   * If the user already give it's email, return it
   * Else, return null
   */
  public get email(): string {
    if (this.isAuthenticated && this.user?.email) return this.user.email;

    return this.userSessionStorage?.email || null;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private context: ApplicationContext,
    private hroadsClient: HroadsClient,
    private oauthService: OAuthService,
    private router: Router,
    private trackingClient: TrackingClient,
    private situationContext: SituationContext,
    private store: Store
  ) {
    this.isAuthenticated$ = this._isAuthenticated$.asObservable();
    this.user$ = this._user$.asObservable();

    this.saveUser = SAVE_USER_CONTEXT_CONFIG(this.context.product);

    /**
     * Ignore Auth Service initialisation for dispatch domain so there is no nonce/state validation
     * error as the nonce and the state are stored in the application domain local storage and so
     * not shared with dispatch domain
     */
    if (!this.context.applicationName || this.context.applicationName === ApplicationName.Dispatch)
      return;

    this.canActivate$ = this.isDoneLoading$.pipe(
      filter((isDoneLoading: boolean) => isDoneLoading),
      map(_ => this.isAuthenticated)
    );

    // Update redirect uri for UAA
    this.oauthService.redirectUri = REDIRECT_URI_CONFIG(
      this.context.product,
      this.context.applicationName
    );
    this.oauthService.silentRefreshRedirectUri = SILENT_REFRESH_REDIRECT_URI_CONFIG(
      this.context.product,
      this.context.applicationName
    );

    // Listen OAuth events
    this.listenOAuthEvents();
    this.listenSessionEnd();

    // Start automatic silent refresh that refreshes the token before it expires
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  /**
   * Initialise OAuth module
   */
  public initAuth(): Observable<void> {
    /**
     * Ignore Auth Service initialisation for dispatch domain so there is no nonce/state validation
     * error as the nonce and the state are stored in the application domain local storage and so
     * not shared with dispatch domain
     */
    if (!this.context.applicationName || this.context.applicationName === ApplicationName.Dispatch)
      return of(null);

    /**
     * Needed as Keycloak does not return at_hash claim in the access token in Code flow. It
     * prevents the application from crashing.
     * https://ordina-jworks.github.io/security/2019/08/22/Securing-Web-Applications-With-Keycloak.html
     */
    this.oauthService.tokenValidationHandler = new NullValidationHandler();

    /**
     * Load the Keycloak endpoints configuration (discovery document) and initiate login so if user
     * is already logged, the session is loaded.
     */
    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      switchMap(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return of(this.successfulLogin());
        }

        if (this.oauthService.getRefreshToken()) {
          // If login has failed (i.e. token expired), try to refresh the token if valid
          if (this.hasValidRefreshToken()) {
            return from(this.oauthService.refreshToken()).pipe(
              catchError(() => {
                // Refresh token has failed so it empties all the previous authentication state
                this.emptyAuthStorage();
                this.emptyUser();
                this.isDoneLoading$.next(true);

                return null;
              }),
              // Refresh token has succeeded
              map(() => this.successfulLogin())
            );
          }

          /**
           * Access and refresh token are expired. So they are deleted to prevent a white page caused
           * by Adventure service refusing the expired access token.
           */
          this.emptyAuthStorage();
        }

        // Login failed and refresh token is missing
        this.isDoneLoading$.next(true);
        this.emptyUser();

        return of(null);
      })
    );
  }

  /* -- LOGIN -- */

  public login(path: string, clientId: string, application?: string): Observable<void> {
    // Remove the initial slash in the path if present
    const returnUrl: string = path[0] === '/' ? path.slice(1) : path;

    // Indicate to keycloak the from app and the from uri
    if (application) {
      this.oauthService.customQueryParams = {
        from_app: application
      };
    }

    // Change clientId
    if (clientId) this.oauthService.clientId = clientId;

    localStorage.setItem('returnUrl', returnUrl);
    localStorage.setItem('inProcessLogin', 'true');

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('overrideSso') === '9a4bb2a8-ff8e-11ed-be56-0242ac120002') {
      return of(this.oauthService.initCodeFlow());
    }

    return this.store.select(selectIsAuthWithSSO).pipe(
      concatMap(IsAuthWithSSO => {
        if (IsAuthWithSSO) {
          return this.hroadsClient.getSsoUrl().pipe(
            map(ssoUrl => {
              this.document.defaultView.location.href = ssoUrl;
            }),
            catchError((_: unknown) =>
              from(this.router.navigateByUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`)).pipe(
                map(() => null)
              )
            )
          );
        }

        return of(this.oauthService.initCodeFlow());
      })
    );
  }

  /**
   * Log a user which has been logged from the Hroads SSO
   *
   * @param user The user
   * @param token The token
   * @returns An observable emitting true if the user is successfully logged in, false otherwise
   */
  public loginFromSSO(user: string, token: string): Observable<boolean> {
    this.oauthService.oidc = false;
    this.oauthService.responseType = '';
    localStorage.setItem('inProcessLogin', 'true');
    this.oauthService.useSilentRefresh = false;
    this.oauthService.responseType = 'code';

    return from(this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(user, token)).pipe(
      tap((userInfo: UserInfo) => userInfo && this.successfulLogin(userInfo?.info)),
      map(info => !!info)
    );
  }

  /* -- LOGOUT -- */

  public logout(noRedirectToLogoutUrl: boolean = false): void {
    this.emptyUser();
    this.oauthService.logOut(noRedirectToLogoutUrl);

    this.store
      .select(selectIsAuthWithSSO)
      .pipe(
        tap(isAuthWithSSO => {
          if (isAuthWithSSO) {
            this.hroadsClient
              .getSsoLogoutUrl()
              .pipe(
                map(ssoUrl => {
                  this.document.defaultView.location.href = ssoUrl;
                }),
                catchError((_: unknown) =>
                  from(
                    this.router.navigateByUrl(`/${AppRouteUrls.ERROR(ErrorCodes.ERROR_404)}`)
                  ).pipe(map(() => null))
                ),
                takeUntil(this.onDestroySubject$)
              )
              .subscribe();
          }
        }),
        takeUntil(this.onDestroySubject$)
      )
      .subscribe();
  }

  /**
   * Remove all the related items in the authentication storage (local storage or session storage
   * according to the configuration)
   */
  public emptyAuthStorage(): void {
    const authStorage: OAuthStorage = authStorageFactory();

    this.AUTH_STORAGE_ITEMS.forEach((item: string) => {
      authStorage.removeItem(item);
    });
  }

  /* -- OAUTH EVENT LISTENERS --  */

  /**
   * Update authentication status on OAuth event
   */
  private listenOAuthEvents(): void {
    this.oauthService.events.pipe(takeUntil(this.onDestroySubject$)).subscribe(event => {
      this._isAuthenticated$.next(this.oauthService.hasValidAccessToken());

      if (event.type === 'token_refresh_error') {
        this.emptyAuthStorage();
      }
    });
  }

  /**
   * Empty user when session ends
   */
  private listenSessionEnd(): void {
    this.oauthService.events
      .pipe(
        filter(
          (event: OAuthEvent) =>
            event.type === 'session_terminated' || event.type === 'session_error'
        ),
        takeUntil(this.onDestroySubject$)
      )
      .subscribe(() => {
        this._isAuthenticated$.next(false);
        this.emptyUser();
      });
  }

  private successfulLogin(userInfo?: UserInfo): void {
    const userToLoad = userInfo || (this.oauthService.getIdentityClaims() as UserInfo);

    this.loadUser(userToLoad);

    if (localStorage.getItem('inProcessLogin')) {
      this.trackingClient
        .actions(this.user)
        .pipe(
          finalize(() => {
            if (this.context.product !== Product.Connect) this.loginRedirect();
          }),
          takeUntil(this.onDestroySubject$)
        )
        .subscribe();

      if (this.context.product === Product.Connect) {
        this.hroadsClient
          .getUser(this.user)
          .pipe(takeUntil(this.onDestroySubject$))
          .subscribe(hroadsUser => {
            this.user.mergeWithHroadsUser(hroadsUser);
            this.situationContext.saveToSituationContext(this.user.situation);
            this.loginRedirect();
          });
      }

      localStorage.removeItem('inProcessLogin');
    }

    this.isDoneLoading$.next(true);
  }

  private loginRedirect(): void {
    const returnUrl = localStorage.getItem('returnUrl');

    if (returnUrl) {
      localStorage.removeItem('returnUrl');
      this.router.navigate([returnUrl]);
    }
  }

  /* -- USER -- */

  private loadUser(userInfos: UserInfo): void {
    this._user$.next(User.fromIdentityClaims(userInfos as unknown as IdentityClaims));
  }

  private emptyUser(): void {
    this._user$.next(null);
  }

  /**
   * Check if the refresh token is present and not expired
   */
  private hasValidRefreshToken(): boolean {
    const refreshToken: string = this.oauthService.getRefreshToken();

    if (!refreshToken) return false;

    const refreshTokenExpiry: number = JSON.parse(atob(refreshToken.split('.')[1])).exp;

    return Math.floor(Date.now() / 1000) < refreshTokenExpiry;
  }
}
