import { Injectable } from '@angular/core';
import { AppRouteUrls } from '@core/routing/app/route-urls';
import { RouteIds } from '@core/routing/route-ids';
import { ApplicationContext, BreakpointService } from '@core/services';
import { MenuEntry } from '@models/menu-entry/menu-entry';
import { Store } from '@ngrx/store';
import { ACCOUNT_ROUTE, EXPEDITION_ROUTE, INTENTIONS_ROUTE, ROADMAPS_ROUTE } from 'app/app.routing';
import { selectIsExpeditionAvailable } from 'app/store/settings/settings.selectors';
import { Observable, combineLatest } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuEntriesService {
  public menuEntries$: Observable<MenuEntry[]>;

  constructor(
    private context: ApplicationContext,
    private store: Store,
    private breakpointService: BreakpointService
  ) {
    this.menuEntries$ = combineLatest([
      this.store.select(selectIsExpeditionAvailable),
      this.breakpointService.isDesktop$
    ]).pipe(
      map(([expedition, isDesktop]) => this.getMenuEntries(expedition, isDesktop)),
      share()
    );
  }

  private getMenuEntries(expeditions: boolean, isDesktop: boolean): MenuEntry[] {
    const menuEntries: MenuEntry[] = [];

    const { applicationName, product } = this.context;

    if (
      !INTENTIONS_ROUTE.data.forbiddenProducts?.includes(product) &&
      !INTENTIONS_ROUTE.data.forbiddenApplications?.includes(applicationName)
    ) {
      menuEntries.push(this.intentionsMenuEntry(isDesktop));
    }

    if (
      !ROADMAPS_ROUTE.data.forbiddenProducts?.includes(product) &&
      !ROADMAPS_ROUTE.data.forbiddenApplications?.includes(applicationName)
    )
      menuEntries.push(this.roadmapsMenuEntry(isDesktop));

    if (
      !EXPEDITION_ROUTE.data.forbiddenProducts?.includes(product) &&
      !EXPEDITION_ROUTE.data.forbiddenApplications?.includes(applicationName) &&
      expeditions
    ) {
      menuEntries.push(this.expeditionsMenuEntry(isDesktop));
    }

    if (
      !ACCOUNT_ROUTE.data.forbiddenProducts?.includes(product) &&
      !ACCOUNT_ROUTE.data.forbiddenApplications?.includes(applicationName)
    )
      menuEntries.push(this.profileMenuEntry(isDesktop));

    return menuEntries;
  }

  private intentionsMenuEntry(isDesktop: boolean): MenuEntry {
    return {
      labelKey: isDesktop ? 'navigation.my-discover' : 'navigation.discover',
      svgIcon: 'pin-village-destination',
      routeId: RouteIds.INTENTIONS,
      link: `/${AppRouteUrls.INTENTIONS}`
    };
  }

  private roadmapsMenuEntry(isDesktop: boolean): MenuEntry {
    return {
      labelKey: isDesktop ? 'roadmap.my-roadmaps' : 'roadmap.roadmaps',
      svgIcon: 'roadmap',
      routeId: RouteIds.ROADMAPS,
      link: `/${AppRouteUrls.ROADMAPS}`
    };
  }

  private expeditionsMenuEntry(isDesktop: boolean): MenuEntry {
    return {
      labelKey: isDesktop ? 'expedition.my-headline' : 'expedition.headline',
      svgIcon: 'explore',
      routeId: RouteIds.EXPEDITION,
      link: `/${AppRouteUrls.EXPEDITION}`,
      materialIcon: true
    };
  }

  private profileMenuEntry(isDesktop: boolean): MenuEntry {
    return {
      labelKey: isDesktop ? 'auth.my-profile' : 'auth.profile',
      svgIcon: 'account_circle',
      routeId: RouteIds.PROFILE,
      link: `/${AppRouteUrls.PROFILE}`,
      materialIcon: true
    };
  }
}
