export enum GenderEmoji {
  lady = '🚺',
  man = '🚹'
}

export enum Status {
  pro = 'pro',
  highschool = 'highschool',
  student = 'student',
  middleschool = 'middleschool',
  unemployed = 'unemployed',
  Bac = 'Bac',
  Bac2 = 'Bac2',
  Bac3 = 'Bac3',
  Bac4 = 'Bac4',
  Bac5 = 'Bac5',
  Bac6 = 'Bac6',
  Bac8 = 'Bac8'
}

export const STATUS_DEPRECATED_HIGHSCHOOL = 'school';
export const STATUS_DEPRECATED_BAC = 'bac1';

export enum HighSchoolClass {
  SEC = 'SEC',
  FST = 'FST',
  TML = 'TML'
}

export enum HighSchoolType {
  PRO = 'PRO',
  GEN = 'GEN',
  TECH = 'TECH',
  GENTECH = 'GENTECH'
}

export const highSchoolTypes = (highSchoolClass: HighSchoolClass): HighSchoolType[] => {
  if (HighSchoolClass.SEC === highSchoolClass)
    return Object.values([HighSchoolType.PRO, HighSchoolType.GENTECH]);

  return Object.values([HighSchoolType.PRO, HighSchoolType.GEN, HighSchoolType.TECH]);
};

export enum HighSchoolTypeUuid {
  PRO = 'PRO',
  GEN = 'GEN',
  TECH = '35a1d1d5-d36e-4b1d-90bb-24ae7f8147fe',
  GENTECH = 'GENTECH'
}

export const highSchoolTypesUuids = (highSchoolType: HighSchoolType): string => {
  switch (highSchoolType) {
    case HighSchoolType.TECH:
      return HighSchoolTypeUuid.TECH;
    default:
      return '';
  }
};
export enum BacGenSpecialty {
  BIOECO = 'BIOECO',
  HISTGEOPS = 'HISTGEOPS',
  HUMLITPHI = 'HUMLITPHI',
  MAT = 'MAT',
  DCS = 'DCS',
  LES = 'LES',
  SET = 'SET',
  SES = 'SES',
  PHYCHEM = 'PHYCHEM',
  FLL = 'FLL',
  ANT = 'ANT',
  ARTS = 'ARTS',
  EPS = 'EPS'
}

export enum BacTechSpecialty {
  BACSTAV = 'BACSTAV',
  BACSTHR = 'BACSTHR',
  BACST2S = 'BACST2S',
  BACSTMG = 'BACSTMG',
  BACSTD2A = 'BACSTD2A',
  BACSTI2D = 'BACSTI2D',
  BACSTL = 'BACSTL',
  BACS2TMD = 'BACS2TMD'
}

// MATCH (dt:DiplomaType {uuid:"35a1d1d5-d36e-4b1d-90bb-24ae7f8147fe"})<-[:IS]-(:Word)<-[:INCLUDES*1..4]-(d:Diploma)<-[ref:REFERENCES]-(p:Partner {hr_uuid:"partner-ideo-onisep"}) return distinct d.name,d.uuid,p.name
// 35a1d1d5-d36e-4b1d-90bb-24ae7f8147fe : bac techno
// reference to partner-ideo-onisep : official
export enum BacTechSpecialtyUuids {
  BACSTAV = '0805343b-13ec-4f17-860e-04498c949789',
  BACSTHR = 'a734e0db-5124-4c29-8463-d12b7ae0e1ed',
  BACST2S = '30636b93-b5b2-4a6b-bfee-55a601f28f91',
  BACSTMG = '9758da52-531a-4ae3-b4ac-fab907bd175d',
  BACSTD2A = '4c42e2dd-ef14-4eed-af0a-aaca4b9e4da0',
  BACSTI2D = '18f399cc-26a3-4586-abcb-d19dec9d9f83',
  BACSTL = 'a841d5f4-fc75-4e00-b413-9ae3553bde18',
  BACS2TMD = 'f74d88d7-7a2d-4902-8c10-a63bb1656653'
}

export enum BacProSpecialty {
  BACPA = 'BACPA',
  BACPAEFDB = 'BACPAEFDB',
  BACPAP = 'BACPAP',
  BACPAG = 'BACPAG',
  BACPBIDT = 'BACPBIDT',
  BACPCDC = 'BACPCDC',
  BACPEM = 'BACPEM',
  BACPEEIEE = 'BACPEEIEE',
  BACPEEDDPI = 'BACPEEDDPI',
  BACPEERDA = 'BACPEERDA',
  BACPISLPB = 'BACPISLPB',
  BACPMDEIM = 'BACPMDEIM',
  BACPMDM = 'BACPMDM',
  BACPMDV = 'BACPMDV',
  BACPMAV = 'BACPMAV',
  BACPMDLEEDSEC = 'BACPMDLEEDSEC',
  BACPM = 'BACPM',
  BACPODBM = 'BACPODBM',
  BACPPEC = 'BACPPEC',
  BACPPM = 'BACPPM',
  BACPRDC = 'BACPRDC',
  BACPSN = 'BACPSN',
  BACPTCB = 'BACPTCB',
  BACPTDEDB = 'BACPTDEDB',
  BACPTDU = 'BACPTDU',
  BACPTDMDSEECT = 'BACPTDMDSEECT',
  BACPTDFEDCDLA = 'BACPTDFEDCDLA',
  BACPTECI = 'BACPTECI',
  BACPTEIDSEECT = 'BACPTEIDSEECT',
  BACPTGT = 'BACPTGT',
  BACPTMA = 'BACPTMA',
  BACPTO = 'BACPTO',
  BACPTDISINT = 'BACPTDISINT',
  BACPTP = 'BACPTP',
  BACPTDBOERDGO = 'BACPTDBOERDGO'
}

export const BAC_SPECIALTY = { ...BacGenSpecialty, ...BacTechSpecialty, ...BacProSpecialty };
export type BacSpecialty = BacGenSpecialty | BacTechSpecialty | BacProSpecialty;

export const bacSpecialties = (highSchoolType: HighSchoolType): string[] => {
  switch (highSchoolType) {
    case HighSchoolType.GEN:
      return Object.values(BacGenSpecialty);
    case HighSchoolType.TECH:
      return Object.values(BacTechSpecialty);
    case HighSchoolType.PRO:
      return Object.values(BacProSpecialty);
    default:
      return [];
  }
};
