import { environment } from 'environments/environment';
import { AnalyticsEventType } from './analytics-event-type';

/**
 * Analytics event model as it is sent to Google Tag Manager
 */
export class AnalyticsEvent {
  /**
   * The environment
   */
  public readonly environment: string;

  /**
   * Analytics event model as it is sent to Google Tag Manager
   *
   * @param event The event type
   */
  constructor(public readonly event: AnalyticsEventType) {
    this.environment = environment.analyticsEnvironment;
  }
}
