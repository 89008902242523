import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService implements OnDestroy {
  public isMobile$: Observable<boolean>;
  public isTablet$: Observable<boolean>;
  public isDesktop$: Observable<boolean>;

  private readonly _isMobile$ = new BehaviorSubject<boolean>(false);
  private readonly _isTablet$ = new BehaviorSubject<boolean>(false);
  private readonly _isDesktop$ = new BehaviorSubject<boolean>(false);
  private readonly onDestroySubject$ = new Subject<void>();

  public get snapshot(): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } {
    return {
      isMobile: this._isMobile$.value,
      isTablet: this._isTablet$.value,
      isDesktop: this._isDesktop$.value
    };
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this._isMobile$.asObservable();
    this.isTablet$ = this._isTablet$.asObservable();
    this.isDesktop$ = this._isDesktop$.asObservable();

    this.breakpointObserver
      .observe([
        Breakpoints.Handset,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.Web
      ])
      .pipe(takeUntil(this.onDestroySubject$))
      .subscribe(() => {
        this._isMobile$.next(this.breakpointObserver.isMatched(Breakpoints.Handset));
        this._isTablet$.next(this.breakpointObserver.isMatched(Breakpoints.TabletPortrait));
        this._isDesktop$.next(
          this.breakpointObserver.isMatched(Breakpoints.Web) ||
            this.breakpointObserver.isMatched(Breakpoints.TabletLandscape)
        );
      });
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }
}
