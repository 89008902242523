import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { FeatureSettingsState } from './settings.state';

export const selectFeaturesSettings = (state: AppState): FeatureSettingsState =>
  state.settings.features;

export const selectIsExpeditionAvailable = createSelector(
  selectFeaturesSettings,
  s => s.expeditions
);

export const selectIsAuthWithSSO = createSelector(selectFeaturesSettings, s => s.authUseSso);

export const selectHasDataTab = createSelector(selectFeaturesSettings, s => s.dataTab);

export const selectHasItinerariesTab = createSelector(
  selectFeaturesSettings,
  s => s.itinerariesTab
);

export const selectHasTestimonyIntention = createSelector(
  selectFeaturesSettings,
  s => s.testimonyIntention
);

export const selectTestimonyUrl = createSelector(selectFeaturesSettings, s => s.testimonyUrl);

export const selectCompanyIntention = createSelector(
  selectFeaturesSettings,
  s => s.companyIntention
);

export const selectJobTeaserPrefix = createSelector(selectFeaturesSettings, s => s.jobTeaserPrefix);

export const selectDiplomaTypes = createSelector(selectFeaturesSettings, s => s.diplomaTypes);

export const selectDiplomaTypesAndBridges = createSelector(selectFeaturesSettings, s => ({
  diplomaTypes: s.diplomaTypes,
  bridges: s.bridges
}));
