import { IFilters } from '../filters.interface';

/**
 * Industry filters
 */
export class IndustryFilters implements IFilters {
  public get activeFilters(): number {
    return +this.industries.length;
  }

  /**
   * Cursus filters
   *
   * @param industries Indicate which industries the results needs to be part of. Is empty if
   *                 this filter is disabled.
   */
  constructor(public industries: string[] = []) {}

  public empty(): void {
    this.industries = [];
  }

  public copy(filter: IndustryFilters): void {
    this.industries = [...filter.industries];
  }
}
