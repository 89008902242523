export enum AdventureType {
  Job = 'job',
  Diploma = 'diploma',
  Definition = 'definition',
  Internship = 'internship',
  DiplomaMajor = 'diplomaMajor',
  Company = 'company',

  Project = 'project'
}
