import { isDiploma, isJob } from '@clients/adventure/bean/waypoint';
import { Campus } from '@models/campus/campus';
import { Currency } from '@models/currency/currency';
import { WaypointType } from '../waypoint/waypoint-type';
import { ApiOffer } from './offer.api';

/**
 * A school offer
 */
export class Offer {
  /**
   * The list of cities of the offer campuses as a string
   */
  public get campusesList(): string {
    return this.campuses.map(offerCampus => offerCampus.city).join(', ');
  }

  /**
   * Return the currency used for the offer
   */
  public get formatCurrency(): Currency {
    return Currency[this.devise] || Currency.Euros;
  }

  /**
   * A school offer
   *
   * @param id The offer id
   * @param label The offer label
   * @param parcoursupId The parcoursup id
   * @param cost the cost of the offer
   * @param devise the local currency of the offer
   * @param campuses are the campuses linked to the offer
   * @param userCount is the number of users (students) of this offer
   * @param isSandwichTraining is sandwichTraining or not
   * @param majors The possible majors for this offer
   */
  constructor(
    public id: string,
    public label: string,
    public cost: number | null,
    public devise: string,
    public campuses: Campus[],
    public userCount: number,
    public isSandwichTraining: boolean,
    public parcoursupId?: string,
    public majors?: string[],
    public is_internship?: boolean
  ) {}

  /**
   * Build the Offer object from the Api Offer object
   *
   * @param apiOffer The Api Offer object
   * @returns The new Offer object
   */
  public static build(apiOffer: ApiOffer): Offer {
    return new Offer(
      apiOffer.id,
      apiOffer.label,
      apiOffer.cost,
      apiOffer.devise,
      apiOffer.campuses || [],
      apiOffer.userCount,
      apiOffer.isSandwichTraining,
      apiOffer.parcoursupId,
      apiOffer.majors || []
    );
  }

  /**
   * Get the Api Offer object from the Offer object
   *
   * @returns The corresponding Api Offer object
   */
  public toApi(): ApiOffer {
    return {
      id: this.id,
      label: this.label,
      parcoursupId: this.parcoursupId,
      cost: this.cost,
      devise: this.devise,
      campuses: this.campuses,
      userCount: this.userCount,
      isSandwichTraining: this.isSandwichTraining,
      majors: this.majors
    } as ApiOffer;
  }

  /**
   * The offer icon based on its waypoint type
   */
  public icon(waypointType: WaypointType): string {
    if (isJob(waypointType)) return '💼';

    if (isDiploma(waypointType)) return '🎓';

    return '';
  }
}
