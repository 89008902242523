import { ApplicationName } from '@models/context/application-name';
import { Product } from '@models/context/product';
import { environment } from 'environments/environment';

const DISPATCH_APPLICATION_NAME = 'dispatch';
const SILENT_REFRESH_FILE_NAME = 'silent-refresh.html';

/**
 * A config setting the redirect URI after authentication
 *
 * @param applicationName The name of the application
 * @returns The redirect URI
 */
export const REDIRECT_URI_CONFIG = (product: Product, applicationName: string): string => {
  switch (applicationName) {
    case ApplicationName.MyRoad:
    case ApplicationName.CmqBtpNumerique:
    case ApplicationName.RegionSud:
      return `${environment.protocol}${environment.hostSuffix[applicationName]}/`;
    default:
      return `${environment.protocol}${DISPATCH_APPLICATION_NAME}.${environment.hostSuffix[product]}?domain=${applicationName}`;
  }
};

/**
 * A config setting the redirect URI after silent refresh authentication
 *
 * @param applicationName The name of the application
 * @returns The silent refresh redirect URI
 */
export const SILENT_REFRESH_REDIRECT_URI_CONFIG = (
  product: Product,
  applicationName: string
): string => {
  switch (applicationName) {
    case ApplicationName.MyRoad:
    case ApplicationName.CmqBtpNumerique:
    case ApplicationName.RegionSud:
      return `${environment.protocol}${environment.hostSuffix[applicationName]}/${SILENT_REFRESH_FILE_NAME}`;
    default:
      return `${environment.protocol}${DISPATCH_APPLICATION_NAME}.${environment.hostSuffix[product]}/${SILENT_REFRESH_FILE_NAME}?domain=${applicationName}`;
  }
};
