import { Status } from '@clients/adventure/bean/situation';
import { Tag } from '@clients/adventure/bean/tag';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { AdventureType } from '@models/adventure-type/adventure-type';
import { ApiDefinition } from '@models/definition/definition.api';

// FIXME: use enum with index
export enum DefinitionType {
  FROM_ADMIN = 'FROM_ADMIN',
  FROM_DATA = 'FROM_DATA',
  FROM_USER = 'FROM_USER',
  FROM_API = 'FROM_API'
}

export class Definition {
  /**
   * The title used for the adventure
   */
  public get title(): string {
    return this.destination?.label || this.name;
  }

  public get originLabel(): string {
    return this.origin?.label;
  }

  public get destinationLabel(): string {
    return this.destination?.label;
  }

  public get tagLabel(): string {
    return this.tag?.label;
  }

  public get adventureType(): AdventureType {
    return this.origin ? AdventureType.Diploma : AdventureType.Job;
  }

  /**
   * @param enabled Whether the definition is enabled or not
   * @param type The type
   * @param statuses The statuses that allows to access this definition
   * @param name The name
   * @param description The description
   * @param id The id
   * @param cost The annual cost if the definition is a definition proposed by a client
   * @param application The application to which the definition is attached
   * @param originId The origin id of the definition if it is in origin mode
   * @param origin The origin of the definition if it is in origin mode
   * @param destinationId The destination id of the definition if it is in journey mode
   * @param destination The destination of the definition if it is in journey mode
   * @param duration The estimated duration to access the diploma
   * @param timeTo The estimated duration to access the job
   * @param tagId The tag id containing this definition
   * @param tag The tag containing this definition
   * @param video A video presenting the definition provided by the client
   */
  constructor(
    public enabled: boolean,
    public type: DefinitionType,
    public statuses: Status[],
    public name: string,
    public description: string,
    public id?: string,
    public cost: number = null,
    public application?: string,
    public originId?: string,
    public origin?: Waypoint,
    public destinationId?: string,
    public destination?: Waypoint,
    public duration?: number,
    public timeTo?: number,
    public tagId?: string,
    public tag?: Tag,
    public video?: string
  ) {}

  public static build(apiDef: ApiDefinition): Definition {
    return new Definition(
      apiDef.enabled,
      apiDef.type,
      apiDef.statuses,
      apiDef.name,
      apiDef.description,
      apiDef.id,
      apiDef.cost,
      apiDef.application,
      apiDef.originId,
      apiDef.origin,
      apiDef.destinationId,
      apiDef.destination,
      apiDef.duration,
      apiDef.timeTo,
      apiDef.tagId,
      apiDef.tag,
      apiDef.video
    );
  }

  public toApi(): ApiDefinition {
    return {
      enabled: this.enabled,
      type: this.type,
      statuses: this.statuses,
      name: this.name,
      description: this.description,
      id: this.id,
      cost: this.cost,
      application: this.application,
      originId: this.originId,
      destinationId: this.destinationId,
      origin: this.origin,
      destination: this.destination,
      tagId: this.tagId,
      tag: this.tag,
      video: this.video
    } as ApiDefinition;
  }
}
