import { Injectable } from '@angular/core';
import { SAVE_USER_CONTEXT_CONFIG } from '@configs/save-user-context.config';
import { Gender } from '@models/gender/gender';
import { Situation } from '@models/situation/situation';
import { ApiSituation } from '@models/situation/situation.api';
import { BehaviorSubject } from 'rxjs';
import { ApplicationContext } from '../application/application.context.service';

@Injectable({ providedIn: 'root' })
export class SituationContext {
  private readonly LAST_SITUATION_CONTEXT_KEY = 'lastSituationContext';
  private readonly _situation$ = new BehaviorSubject<Situation>(null);

  public get situation(): Situation {
    return this._situation$.value;
  }

  public get situation$(): BehaviorSubject<Situation> {
    return this._situation$;
  }

  constructor(private context: ApplicationContext) {}

  /**
   * Initialise the situation context from the local storage
   */
  public init(): void {
    if (!SAVE_USER_CONTEXT_CONFIG(this.context.product)) return;

    const lastSituationContext: ApiSituation = JSON.parse(
      localStorage.getItem(this.LAST_SITUATION_CONTEXT_KEY)
    );

    // Initialise the situation
    if (lastSituationContext) {
      if (lastSituationContext.gender !== Gender.man && lastSituationContext.gender !== Gender.lady)
        localStorage.removeItem(this.LAST_SITUATION_CONTEXT_KEY);
      else this._situation$.next(Situation.build(lastSituationContext));
    }
  }

  /**
   * Save the new user situation context
   *
   * @param situation The new user situation
   */
  public saveToSituationContext(situation: Situation): void {
    if (!SAVE_USER_CONTEXT_CONFIG(this.context.product))
      localStorage.removeItem(this.LAST_SITUATION_CONTEXT_KEY);
    else localStorage.setItem(this.LAST_SITUATION_CONTEXT_KEY, JSON.stringify(situation.toApi()));

    this._situation$.next(situation);
  }

  public empty(): void {
    localStorage.removeItem(this.LAST_SITUATION_CONTEXT_KEY);

    this._situation$.next(null);
  }
}
