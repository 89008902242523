/**
 * An area as it is represented on API
 */
export interface ApiArea {
  /**
   * The area id
   */
  hr_uuid: string;

  /**
   * The area name
   */
  name: string;

  /**
   * The area type
   */
  type: AreaType;

  /**
   * The area latitude
   */
  latitude: number;

  /**
   * The area longitude
   */
  longitude: number;
}

export enum AreaType {
  city = 'city',
  region = 'region',
  country = 'country'
}
