import { Application } from '@clients/adventure/bean/application';
import { ApplicationName } from '@models/context/application-name';

/**
 * A config setting the scope of the application kpis
 *
 * @param application The application
 * @returns the scope of the application kpis
 */
export const KPIS_SCOPE_CONFIG = (application: Application): string => {
  switch (application?.application) {
    case ApplicationName.MyRoad:
    case ApplicationName.PaysDeLaLoire:
    case ApplicationName.RegionSud:
    case ApplicationName.CmqBtpNumerique:
    case ApplicationName.Carto:
      return 'parcoursup';
    default:
      return application.application;
  }
};
