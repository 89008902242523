import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationContext, SituationContext } from '@core/services';
import { Definition } from '@models/definition/definition';
import { ApiDefinition } from '@models/definition/definition.api';
import { Filters } from '@models/filters/filters';
import { Gender } from '@models/gender/gender';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DefinitionClient {
  private readonly DEFINITION_URI = `${environment.adventureUri}/definitions`;

  constructor(
    private context: ApplicationContext,
    private http: HttpClient,
    private situationContext: SituationContext
  ) {}

  /**
   * Get a definition from an id
   *
   * @param definitionId The definition id
   * @returns The matching definition
   */
  public getDefinition(definitionId: string): Observable<Definition> {
    return this.http
      .get<ApiDefinition>(`${this.DEFINITION_URI}/${definitionId}`)
      .pipe(map(apiDefinition => Definition.build(apiDefinition)));
  }

  /**
   * Get all the definitions for a given application
   *
   * @returns The matching definitions
   */
  public definitionList(): Observable<Definition[]> {
    const params = new HttpParams().set('a', this.context.applicationName);

    return this.http
      .get<ApiDefinition[]>(`${this.DEFINITION_URI}/list`, { params })
      .pipe(map(apiDefinition => apiDefinition.map(apiDef => Definition.build(apiDef))));
  }

  /**
   * Get all enabled and FROM_ADMIN definitions by the application, status and tag id
   *
   * @param definitionFilters Filters
   * @param tagId The tag id
   * @returns The matching definitions
   */
  public getDefinitions(definitionFilters: Filters, tagId?: string): Observable<Definition[]> {
    let params = new HttpParams()
      .set('a', this.context.applicationName)
      .set('status', this.situationContext.situation.status)
      .set('gender', this.situationContext.situation.gender);

    if (this.situationContext.situation.isHighSchool)
      params = params.set('h', this.situationContext.situation.highSchoolClass);

    if (tagId) params = params.set('t', tagId);

    return this.http
      .post<ApiDefinition[]>(`${this.DEFINITION_URI}/list`, definitionFilters.toApi(), { params })
      .pipe(map(apiDefinition => apiDefinition.map(apiDef => Definition.build(apiDef))));
  }

  /**
   * Create a new definition
   *
   * @param definition The new definition
   * @returns The created definition
   */
  public saveDefinition(definition: Definition): Observable<Definition> {
    return this.http
      .post<ApiDefinition>(this.DEFINITION_URI, definition.toApi())
      .pipe(map(apiDefinition => Definition.build(apiDefinition)));
  }

  /**
   * Update an existing definition
   *
   * @param definition The definition to update
   * @returns The updated definition
   */
  public updateDefinition(definition: Definition): Observable<Definition> {
    return this.http
      .put<ApiDefinition>(this.DEFINITION_URI, definition.toApi())
      .pipe(map(apiDefinition => Definition.build(apiDefinition)));
  }

  /**
   * Delete a definition
   *
   * @param definition The definition to delete
   */
  public deleteDefinition(definition: Definition): Observable<void> {
    return this.http.delete<void>(`${this.DEFINITION_URI}/${definition.id}`);
  }

  /**
   * Create definitions from existing offers
   *
   * @returns The created definitions
   */
  public createDefinitionsFromOffers(): Observable<Definition[]> {
    const params = new HttpParams().set('a', this.context.applicationName);

    return this.http
      .get<ApiDefinition[]>(`${this.DEFINITION_URI}/creates-from-offers`, {
        params
      })
      .pipe(map(apiDefinition => apiDefinition.map(apiDef => Definition.build(apiDef))));
  }

  /**
   * Created definitions from existing KPIs
   *
   * @returns The created definitions
   */
  public createDefinitionsFromKpis(): Observable<Definition[]> {
    const params = new HttpParams().set('a', this.context.applicationName);

    return this.http
      .get<ApiDefinition[]>(`${this.DEFINITION_URI}/creates-from-kpis`, {
        params
      })
      .pipe(map(apiDefinition => apiDefinition.map(apiDef => Definition.build(apiDef))));
  }

  /**
   * Get similar definitions from a definition
   *
   * @param definition The definition
   * @param gender The user gender
   * @returns The similar definitions
   */
  public similarDefinitions(definition: Definition, gender: Gender): Observable<Definition[]> {
    const params = new HttpParams().set('gender', gender);

    return this.http
      .post<ApiDefinition[]>(`${this.DEFINITION_URI}/similar`, definition.toApi(), { params })
      .pipe(map(apiDefinition => apiDefinition.map(apiDef => Definition.build(apiDef))));
  }
}
