import { IFilters } from '../filters.interface';

/**
 * Cursus filters
 */
export class CursusFilters implements IFilters {
  public static readonly NO_EXAMS = 'no-exams';

  public get activeFilters(): number {
    return +this.free + +!!this.maxCost + +this.isPublic + +!!this.requiredExams.length;
  }

  /**
   * Cursus filters
   *
   * @param free Indicate whether results have to be free
   * @param maxCost Indicate the maximum annually cost that results have to cost
   * @param isPublic Indicate if results have to be public
   * @param requiredExams Indicate if results need to have exams from the selected list. Is empty if
   *                 this filter is disabled.
   */
  constructor(
    public free: boolean = false,
    public maxCost: number = 0,
    public isPublic: boolean = false,
    public requiredExams: string[] = []
  ) {
    if (requiredExams.includes(CursusFilters.NO_EXAMS) && requiredExams.length > 1)
      this.requiredExams = [CursusFilters.NO_EXAMS];

    // Disable max cost filter if free filter is defined
    if (free && maxCost) this.maxCost = 0;
  }

  public empty(): void {
    this.free = false;
    this.isPublic = false;
    this.maxCost = 0;
    this.isPublic = false;
    this.requiredExams = [];
  }

  public copy(filter: CursusFilters): void {
    this.free = filter.free;
    this.isPublic = filter.isPublic;
    this.maxCost = filter.maxCost;
    this.isPublic = filter.isPublic;
    this.requiredExams = [...filter.requiredExams];
  }
}
