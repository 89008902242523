import { Area } from '../../area/area';
import { IFilters } from '../filters.interface';

/**
 * Localisation filters
 */
export class LocalisationFilters implements IFilters {
  public get activeFilters(): number {
    return +this.area.length || +!!this.userGeolocation;
  }

  /**
   * Localisation filters
   *
   * @param userGeolocation The user geolocation if results have to be around him. Is null if this
   *                        filter is disabled. It can not be enabled if area is enabled
   * @param area A selected area if results have to be nearby or in the selected area. Is null if this
   *             filter is disabled. It can not be enabled if user geolocation is enabled
   */
  constructor(public userGeolocation: GeolocationCoordinates = null, public area: Area[] = []) {}

  public empty(): void {
    this.area = [];
    this.userGeolocation = null;
  }

  public copy(filter: LocalisationFilters): void {
    this.area = [...filter.area];
    this.userGeolocation = filter.userGeolocation;
  }
}
