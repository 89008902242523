import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private readonly id = 'typeform-script';

  constructor(@Inject(DOCUMENT) private document: Document, private zone: NgZone) {}

  /**
   * Load a script
   *
   * @param url The script url
   * @returns An observable that emits when the script is loaded and was not already loaded, then completes ; or errors when it fails
   */
  public loadScript(url: string, noReload = true): Observable<void> {
    return this.zone.runOutsideAngular<Observable<void>>(
      (): Observable<void> =>
        new Observable(subscriber => {
          if (this.isScriptLoaded(url) && noReload) {
            subscriber.complete();

            return;
          }

          const head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];
          const script: HTMLScriptElement = this.document.createElement('script');

          script.type = 'text/javascript';
          script.async = true;
          script.src = url;

          script.onload = (): void => {
            subscriber.next();
            subscriber.complete();
          };

          script.onerror = (): void => {
            if (!environment.production) console.error(`Loading script ${url} has failed !`);

            subscriber.error();
          };

          head.appendChild(script);
        })
    );
  }

  private isScriptLoaded(url: string): boolean {
    return this.document.querySelectorAll(`script[src="${url}"]`).length > 0;
  }
}
