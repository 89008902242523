import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_LANG } from '@configs/app.config';
import { ApplicationClient, ApplicationContext } from '@core/services';
import { Area } from '@models/area/area';
import { ApiArea } from '@models/area/area.api';
import { KpiItem, KpiParams, KpiResult } from '@models/kpi.model';
import { ApiHroadsUser } from '@models/user/hroads-user.api';
import { User } from '@models/user/user';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, merge, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HroadsClient {
  private readonly HROADS_URI = '/apis/hroads/api/1';

  /* -- AUTHENTICATION -- */
  private readonly CLIENT_ID = 'e0a82a50-cc4c-4c28-8e8a-f7ed6c8cadd8';
  private readonly CLIENT_SECRET = 'cdb689d47573';
  private readonly DATAVIZ_SCOPE = 'dataviz';
  private readonly USERS_SCOPE = 'users';

  constructor(
    private context: ApplicationContext,
    private applicationClient: ApplicationClient,
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  /* -- KPI -- */

  public kpi(
    nodeId: string,
    kpiName: string,
    limit: number,
    application: string,
    body: KpiParams = {}
  ): Observable<KpiItem[]> {
    const postBody: KpiParams = {
      node: nodeId,
      limit,
      ...body
    };

    postBody.filters = {
      ...postBody.filters,
      limit_r_threshold: 15000,
      locale: this.translateService.currentLang || this.translateService.defaultLang || DEFAULT_LANG
    };

    if (!postBody.filters?.application) {
      postBody.filters.application = application;
    }

    if (!postBody.filters?.optional_nomenclatures) {
      postBody.filters.optional_nomenclatures = false;
    }

    return this.token().pipe(
      mergeMap(token => {
        const headers = new HttpHeaders().set('X-Authorization', token);

        return this.http
          .post<KpiResult>(`${this.HROADS_URI}/dataviz/${kpiName}`, postBody, { headers })
          .pipe(
            map(res => {
              // res is not returned so its name is saved in its first child
              if (res.results && res.results.length > 0) {
                res.results[0].kpiName = res.name;
              }

              return res.results;
            })
          );
      })
    );
  }

  /**
   * Search an area matching a search text
   *
   * @param searchText The search text
   * @param limit The result limit number
   * @returns An array of area names
   */
  public searchArea(searchText: string, limit: number): Observable<Area[]> {
    return this.token().pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('X-Authorization', token);

        const params = new HttpParams().set('limit', `${limit}`);

        return this.http.get<ApiArea[]>(`${this.HROADS_URI}/area/search/${searchText}`, {
          headers,
          params
        });
      }),
      map(apiAreas => apiAreas.map(apiCity => Area.build(apiCity)))
    );
  }

  /**
   * Get additional information of a user from the Hroads API
   *
   * @param user The user
   * @returns An observable emitting the user from the Hroads API
   */
  public getUser(user: User): Observable<ApiHroadsUser> {
    const token$ = this.token('test', this.USERS_SCOPE);

    const applicationInContext$ = of(this.context.application?.nodeId).pipe(
      filter(applicationNodeId => !!applicationNodeId),
      map(applicationNodeId => applicationNodeId)
    );

    const applicationNotInContext$ = of(this.context.application?.nodeId).pipe(
      filter(applicationNodeId => !applicationNodeId),
      switchMap(() =>
        this.applicationClient
          .getApplication(this.context.applicationName)
          .pipe(map(application => application.nodeId))
      )
    );

    const applicationNodeId$ = merge(applicationInContext$, applicationNotInContext$);

    return combineLatest([token$, applicationNodeId$]).pipe(
      switchMap(([token, applicationNodeId]) => {
        const params = new HttpParams()
          // FIXME: Ben The application is sometimes null here
          .set('app', applicationNodeId)
          .set('current', 1)
          .set('details', 1);

        const headers = new HttpHeaders()
          .set('X-Authorization', token)
          .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
          .set('Pragma', 'no-cache')
          .set('Expires', '0');

        return this.http.get<ApiHroadsUser>(`${this.HROADS_URI}/users/${user.email}`, {
          params,
          headers
        });
      })
    );
  }

  /**
   * Get the SSO URL for the context
   *
   * @returns An observable emitting the SSO URL
   */
  public getSsoUrl(): Observable<string> {
    return this.http
      .get<{ url: string }>(
        `${this.HROADS_URI}/${this.context.applicationName}/sso_url?product=${this.context.product}`
      )
      .pipe(map(({ url }: { url: string }) => url));
  }

  /**
   * Get the SSO Logout URL for the context
   *
   * @returns An observable emitting the SSO Logout URL
   */
  public getSsoLogoutUrl(): Observable<string> {
    return this.http
      .get<{ url: string }>(
        `${this.HROADS_URI}/${this.context.applicationName}/sso_logout_url?product=${this.context.product}`
      )
      .pipe(map(({ url }: { url: string }) => url));
  }

  /* -- AUTHENTICATION -- */

  private token(uuid?: string, scope = this.DATAVIZ_SCOPE): Observable<string> {
    let params = new HttpParams()
      .set('client_id', this.CLIENT_ID)
      .set('client_secret', this.CLIENT_SECRET)
      .set('scope', scope);

    if (uuid) params = params.set('uuid', uuid);

    return this.http
      .get<{ token: string }>(`${this.HROADS_URI}/token`, { params })
      .pipe(map(({ token }: { token: string }) => token));
  }
}
