import { Status } from '@clients/adventure/bean/situation';
import { Gender } from '@models/gender/gender';

/**
 * Resolve Status based on status enum
 */
export const handleBacStatus = (status: Status): string => {
  if (status.startsWith(Status.Bac.toString()) || Status[status] === Status.student)
    return Status.student.toString();

  return status;
};

/**
 * Resolve image based on a status and a gender
 */
export const situationIcon = (status: Status, gender: Gender): string => {
  if (gender !== Gender.man && gender !== Gender.lady)
    return `${handleBacStatus(status)}-${Gender.man.toString()}`;

  return `${handleBacStatus(status)}-${gender.toString()}`;
};

/**
 * Resolve status key for i18n
 */
export const statusKeyLabel = (status: Status, gender: Gender): string =>
  `situations.status.${handleBacStatus(status)}.${gender === Gender.lady ? 'W' : 'M'}`;

/**
 * Resolve High School Class key for i18n
 */
export const highSchoolClassKeyLabel = (grade: string): string => `situations.highschool.${grade}`;

/**
 * Resolve High School Type key for i18n
 */
export const highSchoolTypeKeyLabel = (sector: string): string =>
  `situations.highSchoolType.${sector}`;

/**
 * Resolve Speciality key for i18n
 */
export const specialityKeyLabel = (speciality: string): string =>
  `situations.specialities.${speciality}`;
