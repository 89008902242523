import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tag } from '@clients/adventure/bean/tag';
import { ApplicationContext } from '@core/services';
import { WaypointType } from '@models/waypoint/waypoint-type';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TagClient {
  private readonly TAGS_URI = `${environment.adventureUri}/tags`;

  constructor(private context: ApplicationContext, private http: HttpClient) {}

  /* -- TAG -- */

  public tags(waypointType?: WaypointType): Observable<Tag[]> {
    let params = new HttpParams();

    params = params.set('a', this.context.applicationName);

    if (waypointType) {
      params = params.set('type', waypointType.toString());
    }

    return this.http.get<Tag[]>(this.TAGS_URI, { params });
  }

  public saveTag(tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(this.TAGS_URI, tag);
  }

  public updateTag(tag: Tag): Observable<Tag> {
    return this.http.put<Tag>(this.TAGS_URI, tag);
  }

  public deleteTag(tag: Tag): Observable<void> {
    return this.http.delete<void>(`${this.TAGS_URI}/${tag.id}`);
  }

  // API

  /**
   * Retrieve apis for tag
   */
  public tagApis(): Observable<string[]> {
    return this.http.get<string[]>(`${this.TAGS_URI}/apis`);
  }
}
