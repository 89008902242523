import { User } from '@models/user/user';
import { ApiUser } from '@models/user/user.api';

export enum Type {
  CONTACT_ADMISSION = 'CONTACT_ADMISSION',
  CONTACT_SCHOOL = 'CONTACT_SCHOOL',
  ROADMAP = 'ROADMAP',
  SIGN_IN = 'SIGN_IN'
}

/**
 * Represents a lead for a specific application
 */
export abstract class Lead {
  // FIXME: interface ?

  /**
   * @param id The lead id
   * @param application The application on which the lead is linked
   * @param email The user email
   * @param user The user
   * @param type The type of the lead
   * @protected
   */
  protected constructor(
    public id: string,
    public application: string,
    public email: string,
    public user: User,
    public type: Type
  ) {}
}

/**
 * A lead as it is represented on API
 */
export interface ApiLead {
  /**
   * The lead id
   */
  id: string;

  /**
   * The application on which the lead is linked
   */
  application: string;

  /**
   * The user email
   */
  email: string;

  /**
   * The user
   */
  apiUser: ApiUser;

  /**
   * The type of the lead
   */
  type: Type;
}
