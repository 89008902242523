import { Poi, Step } from '@clients/poi/poi';
import { IHuman, IMedia } from '@models/company/company';
import { Gender } from '@models/gender/gender';
import { PoiProvider } from '../poi-provider';
import { PoiType } from '../poi-type';
import { ApiHumanPoi, IPartner } from './human-poi.api';

export const convertHumanPoiInHuman = (humanPoi: HumanPoi): IHuman => ({
  id: humanPoi.id,
  name: humanPoi.name,
  username: humanPoi.username,
  gender: humanPoi.gender,
  steps: humanPoi.steps,
  media: humanPoi.media,
  type: humanPoi.type,
  provider: humanPoi.provider,
  anonym: false,
  ambassadorLogo: humanPoi.ambassadorLogo,
  socialUrl: humanPoi.socialNetworkLink,
  socialLabel: humanPoi.socialNetworkLabel,
  partners: humanPoi.partners
});

/**
 * Human POI
 * It can be an alumni or a current student
 */
export class HumanPoi implements Poi {
  /**
   * The POI type
   * As a Human POI, it is always PoiType.Chat
   */
  public readonly type: PoiType = PoiType.Chat;

  /**
   * The POI provider
   * As a Human POI, it is always PoiProvider.human
   */
  public readonly provider: PoiProvider = PoiProvider.human;

  public rect?: DOMRect;

  private readonly LINKEDIN = 'LinkedIn';
  private readonly LINKEDIN_MAIL = '@linkedin';
  private readonly LINKEDIN_URL = 'https://www.linkedin.com/in/';
  private readonly VIADEO = 'Viadeo';
  private readonly VIADEO_MAIL = '@viadeo';
  private readonly VIADEO_URL = 'https://viadeo.journaldunet.com/p/';

  private _testimonyEmoji: string;
  private _isFromLinkedIn = false;
  private _isFromViadeo = false;
  private _socialNetworkLink = '';

  /**
   * The testimony emoji
   * '🔈' if the best testimony from the Human POI is a video testimony
   * '💬' if the best testimony from this Human POI is a text testimony
   * Empty string if the Human POI has no testimony
   */
  public get testimonyEmoji(): string {
    return this._testimonyEmoji;
  }

  /**
   * True if the Human POI is loaded from LinkedIn or Viadeo. False otherwise
   */
  public get isFromSocialNetwork(): boolean {
    return !!(this._isFromLinkedIn || this._isFromViadeo);
  }

  /**
   * The link to the Human POI profile on LinkedIn or Viadeo
   */
  public get socialNetworkLink(): string {
    return this._socialNetworkLink;
  }

  /**
   * The name of the social network where the Human POI profile exists
   */
  public get socialNetworkLabel(): string {
    if (this._isFromLinkedIn) return this.LINKEDIN;

    if (this._isFromViadeo) return this.VIADEO;

    return '';
  }

  public get hasSocialNetwork(): boolean {
    return this.isFromSocialNetwork && !!this.socialNetworkLink;
  }

  /**
   * Human POI
   * It can be an alumni or a current student
   *
   * @param id The Human POI id
   * @param name The Human POI name (used as a label)
   * @param username The Human POI username. It represents its ID on LinkedIn or Viadeo
   * @param gender The Human POI gender
   * @param steps The Human POI steps. It is the steps the human POI has been through during its
   *              studies and its career
   * @param ambassadorLogo Contains ambassador's logo path
   * @param media The optional human POI media
   */
  constructor(
    public id: string,
    public name: string,
    public username: string,
    public gender: Gender,
    public steps: Step[],
    public ambassadorLogo: string,
    public media?: IMedia,
    public partners?: IPartner[]
  ) {
    this.initTestimonyEmoji();
    this.initSocialNetworkLink();
  }

  /**
   * Build the Human POI object from the Api Human POI object
   *
   * @param apiHumanPoi The Human POI response from Api
   */
  public static build(apiHumanPoi: ApiHumanPoi): HumanPoi {
    return new HumanPoi(
      apiHumanPoi.id,
      apiHumanPoi.name,
      apiHumanPoi.username,
      apiHumanPoi.gender,
      apiHumanPoi.steps,
      apiHumanPoi.ambassadorLogo,
      apiHumanPoi.media,
      apiHumanPoi.partners
    );
  }

  /**
   * Initialise the testimony emoji
   * Among all the human POI's steps :
   *  - it returns an audio emoji if any has a video testimony,
   *  - otherwise, it returns a text emoji if any has a text testimony,
   *  - otherwise, there is no testimony so it returns an empty string
   *
   * @returns The best testimony emoji according to the human poi steps
   */
  private initTestimonyEmoji(): void {
    this._testimonyEmoji = this.steps.reduce((emoji: string, step: Step) => {
      /**
       * This step can not have a better testimony than the current one in emoji if :
       * - this step testimony does not exist,
       * - this step does not have any testimony,
       * - the current testimony in emoji is a video (the step testimony is either as good as the
       *    one in emoji if having a video testimony or worst than the video in emoji if having
       *    another type of testimony - test or no testimony -),
       * - the current testimony in emoji is a text and this step does not have a video testimony
       *    (the step testimony is either as good as the one in emoji if having a test testimony
       *    or worst than the test in emoji if having no testimony)
       */
      if (
        !step.testimony ||
        (!step.testimony.video_url && !step.testimony.text) ||
        emoji === '🔈' ||
        (emoji === '💬' && !step.testimony?.video_url)
      )
        return emoji;

      /**
       * The testimony in the emoji is not a video testimony and the step testimony is a video so
       * it is the new best one
       */
      if (step.testimony.video_url) return '🔈';

      /**
       * The testimony in the emoji is not a test testimony and the step testimony is nor missing
       * neither a video so it is a text so it is the new best one
       */
      return '💬';
    }, '');
  }

  /**
   * Initialise the social network link based on the Human POI username
   */
  private initSocialNetworkLink(): void {
    if (this.username.includes(this.LINKEDIN_MAIL)) {
      this._socialNetworkLink =
        this.LINKEDIN_URL + this.username.substring(0, this.username.indexOf('@'));
      this._isFromLinkedIn = true;
    }

    if (this.username.includes(this.VIADEO_MAIL)) {
      this._socialNetworkLink =
        this.VIADEO_URL + this.username.substring(0, this.username.indexOf('@'));
      this._isFromViadeo = true;
    }
  }
}
