import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Industry } from '@models/industry/exam';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface Diploma {
  id: string;
  name: string;
  majors?: DiplomaMajor[];
}

export interface DiplomaMajor {
  id: string;
  name: string;
}

export interface DiplomaType {
  id: string;
  name: string;
  diplomasName: string[];
  diplomasValue: string[];
}

export interface AddResumePayload {
  diploma_id: string;
  major_id: string;
  start: number; // Timestamp
  end: number; // Timestamp
}

@Injectable({ providedIn: 'root' })
export class DataClient {
  private readonly DATA_URI = `${environment.adventureUri}/datas`;

  constructor(private http: HttpClient) {}

  public getDiplomasWithMajors(): Observable<Diploma[]> {
    return this.http.get<Diploma[]>(`${this.DATA_URI}/diplomas-with-majors`);
  }

  public getDiplomasNameWithDiplomaType(diplomaType: string): Observable<DiplomaType> {
    return this.http.get<DiplomaType>(
      `${this.DATA_URI}/diplomas-name-with-diploma-type/${diplomaType}`
    );
  }

  public addResume(body: AddResumePayload): Observable<HttpResponse<unknown>> {
    return this.http.post<unknown>(`${this.DATA_URI}/add-resume`, body, {
      observe: 'response'
    });
  }

  public deleteResume(offerId: string): Observable<void> {
    return this.http.delete<void>(`${this.DATA_URI}/resumes/${offerId}`);
  }

  public getMajorsFromOffer(offerId: string): Observable<DiplomaMajor[]> {
    return this.http.get<DiplomaMajor[]>(`${this.DATA_URI}/majors-from-offer/${offerId}`);
  }

  public getIndustries(): Observable<Industry[]> {
    return this.http.get<Industry[]>(`${this.DATA_URI}/industries`);
  }
}
