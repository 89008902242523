import { ApiArea, AreaType } from './area.api';

/**
 * An area
 */
export class Area {
  public get countryCode(): string {
    return this.id.slice(-2).toUpperCase();
  }

  /**
   * An area
   *
   * @param id The area id
   * @param name The area name
   * @param type The area type
   * @param geolocation The area geolocation
   */
  constructor(
    public id: string,
    public name: string,
    public type: AreaType,
    public geolocation?: GeolocationCoordinates
  ) {}

  /**
   * Build the area object from the Api area object
   *
   * @param apiArea The Api area object
   * @returns The new area object
   */
  public static build(apiArea: ApiArea): Area {
    if (apiArea.type === AreaType.city) {
      return new Area(apiArea.hr_uuid, apiArea.name, apiArea.type, {
        latitude: apiArea.latitude,
        longitude: apiArea.longitude
      } as GeolocationCoordinates);
    }

    return new Area(apiArea.hr_uuid, apiArea.name, apiArea.type);
  }

  /**
   * Get the Api area object from the area object
   *
   * @returns The corresponding Api area object
   */
  public toApi(): ApiArea {
    return {
      hr_uuid: this.id,
      name: this.name,
      latitude: this.geolocation.latitude,
      longitude: this.geolocation.longitude
    } as ApiArea;
  }
}
