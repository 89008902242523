import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Application } from '@clients/adventure/bean/application';
import { ApiLeadContact, LeadContact } from '@models/lead/lead-contact';
import { ApiLeadContactSchool, LeadContactSchool } from '@models/lead/lead-contact-school';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const APPLICATION_URI = `${environment.adventureUri}/applications`;

@Injectable({
  providedIn: 'root'
})
export class ApplicationClient {
  private readonly SCHOOL_URI = `${environment.adventureUri}/schools`;

  constructor(private http: HttpClient) {}

  // -- APPLICATION --

  /**
   * Get an application by the application property
   *
   * @param applicationName The name of an application
   * @returns An observable emitting the matching application
   */
  public getApplication(applicationName: string): Observable<Application> {
    return this.http.get<Application>(`${APPLICATION_URI}/${applicationName}`).pipe(
      map((application: Application) => ({
        ...application,
        videos: application.videos || [],
        images: application.images || [],
        statuses: application.statuses || [],
        campus: application.campus || []
      }))
    );
  }

  /**
   * Save Application
   *
   * @param application to save
   */
  public saveApplication(application: Application): Observable<Application> {
    return this.http.put<Application>(APPLICATION_URI, application);
  }

  /**
   * Get an application by the ID of a school
   *
   * @param schoolId The ID of the school
   * @returns An observable emitting the matching application
   */
  public getSchoolApplication(schoolId: string): Observable<Application> {
    return this.http.get<Application>(`${this.SCHOOL_URI}/${schoolId}`);
  }

  /**
   * Save application info corresponding to a school
   *
   * @param application The application of the school
   * @returns The saved application
   */
  public saveSchoolApplication(application: Application): Observable<Application> {
    return this.http.put<Application>(this.SCHOOL_URI, application);
  }

  // -- UPLOAD FOR APPLICATION--

  /**
   * Upload an image within the application images array
   *
   * @param applicationName The application name
   * @param formData The form data containing the image file in a property called 'file'
   */
  public uploadImages(applicationName: string, formData: FormData): Observable<string> {
    return this.http
      .post<{ path: string }>(`${APPLICATION_URI}/${applicationName}/image/upload`, formData)
      .pipe(map(uploadedImage => uploadedImage.path));
  }

  /**
   * Upload ambassador(square) logo fo Application
   *
   * @param applicationName
   * @param formData
   */
  public uploadAmbassadorLogo(applicationName: string, formData: FormData): Observable<string> {
    return this.http
      .post<{ path: string }>(`${APPLICATION_URI}/${applicationName}/square-logo/upload`, formData)
      .pipe(map(uploadedImage => uploadedImage.path));
  }

  /**
   * Upload application logo
   *
   * @param applicationName The name of the Application
   * @param formData The form data containing the image file in a property called 'file'
   */
  public uploadApplicationLogo(applicationName: string, formData: FormData): Observable<string> {
    return this.http
      .post<{ path: string }>(`${APPLICATION_URI}/${applicationName}/logo/upload`, formData)
      .pipe(map(uploadedImage => uploadedImage.path));
  }

  /**
   * Upload an image within the application images array
   *
   * @param applicationName The application name
   * @param image The path to the image file
   */
  public deleteImage(applicationName: string, image: string): Observable<void> {
    const imagePathSegments: string[] = image.split('/');
    const params = new HttpParams().set('file', imagePathSegments[imagePathSegments.length - 1]);

    return this.http.post<void>(`${APPLICATION_URI}/${applicationName}/image/delete`, null, {
      params
    });
  }

  // -- UPLOAD FOR SCHOOL --

  /**
   * Upload an image within the school images array
   *
   * @param nodeId The nodeId of the School
   * @param formData The form data containing the image file in a property called 'file'
   */
  public uploadSchoolImages(nodeId: string, formData: FormData): Observable<string> {
    return this.http
      .post<{ path: string }>(`${this.SCHOOL_URI}/${nodeId}/image/upload`, formData)
      .pipe(map(uploadedImage => uploadedImage.path));
  }

  /**
   * Upload School logo
   *
   * @param nodeId The nodeId of the School
   * @param formData The image file
   */
  public uploadSchoolLogo(nodeId: string, formData: FormData): Observable<string> {
    return this.http
      .post<{ path: string }>(`${this.SCHOOL_URI}/${nodeId}/logo/upload`, formData)
      .pipe(map(uploadedImage => uploadedImage.path));
  }

  /**
   * Upload an image within the school images array
   *
   * @param nodeId The nodeId of the School
   * @param image The path to the image file
   */
  public deleteSchoolImage(nodeId: string, image: string): Observable<void> {
    const imagePathSegments: string[] = image.split('/');
    const params = new HttpParams().set('file', imagePathSegments[imagePathSegments.length - 1]);

    return this.http.post<void>(`${this.SCHOOL_URI}/${nodeId}/image/delete`, null, {
      params
    });
  }

  // -- CONTACT --

  /**
   * Check if a lead for contact admission exists
   *
   * @param userEmail The user's email
   */
  public hasContactAdmission(userEmail: string): Observable<boolean> {
    const params = new HttpParams().set('e', userEmail);

    return this.http.get<boolean>(`${APPLICATION_URI}/contact-admission`, {
      params
    });
  }

  /**
   * Create a lead for a contact admission action & email the school
   *
   * @param leadContact The lead contact for the admission
   */
  public contactAdmission(leadContact: LeadContact): Observable<LeadContact> {
    return this.http
      .post<ApiLeadContact>(`${APPLICATION_URI}/contact-admission`, leadContact.toApi())
      .pipe(map(lead => LeadContact.build(lead)));
  }

  /**
   * Check if a lead for contact admission exists
   *
   * @param userEmail The user's email
   * @param schoolUuid The school's uuid
   */
  public hasContactSchool(userEmail: string, schoolUuid: string): Observable<boolean> {
    const params = new HttpParams().set('e', userEmail).set('s', schoolUuid);

    return this.http.get<boolean>(`${APPLICATION_URI}/contact-school`, {
      params
    });
  }

  /**
   * Create a lead for a contact school action & send an email from the user to the school
   *
   * @param leadContactSchool The lead contact school
   */
  public contactSchool(leadContactSchool: LeadContactSchool): Observable<LeadContactSchool> {
    return this.http
      .post<ApiLeadContactSchool>(`${APPLICATION_URI}/contact-school`, leadContactSchool.toApi())
      .pipe(map(lead => LeadContactSchool.build(lead)));
  }
}
