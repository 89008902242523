export enum Product {
  /**
   * GPS Instance (My Road, GPS Region, Recruter)
   */
  Explore = 'explore',
  /**
   * Connecter
   */
  Connect = 'connect',
  /**
   * GPS Instance (GPS Region, Recruter, My Road) in show mode
   */
  Show = 'salon'
}
