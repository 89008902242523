import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationContext, ScriptLoaderService, SituationContext } from '@core/services';
import { AnalyticsEvent } from '@models/analytics-event/analytics-event';
import { AnalyticsEventType } from '@models/analytics-event/analytics-event-type';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { delay, filter, ignoreElements, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements OnDestroy {
  public hasGivenChoice$ = new BehaviorSubject<boolean>(false);

  private readonly CONSENT_PRIVACY_POLICY_STORAGE_KEY = 'consent-privacy-policy';
  private readonly CONSENT_PRIVACY_POLICY_STORAGE_VALUE = 'allow';
  private readonly DISMISS_BANNER_STORAGE_KEY = 'dismiss-banner';
  private readonly GTM_SCRIPT_URL_PREFIX = 'https://www.googletagmanager.com/gtm.js';
  private readonly GTM_ID = 'GTM-N7J4D58';
  private readonly GTM_SCRIPT_URL = `${this.GTM_SCRIPT_URL_PREFIX}?id=${this.GTM_ID}`;
  private readonly DATA_LAYER_KEY = 'dataLayer';
  private onDestroySubject$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private context: ApplicationContext,
    private router: Router,
    private scriptLoaderService: ScriptLoaderService,
    private situationContext: SituationContext
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(0), // Ensure that title resolution in PageTitleService has been achieved before pushing the NavigationEnd tag
        takeUntil(this.onDestroySubject$)
      )
      .subscribe(() => {
        this.pushTag(new AnalyticsEvent(AnalyticsEventType.NavigationEnd));
        this.pushPageView();
      });
  }

  public pushPageView(pageLocation?: string, pageTitle?: string): void {
    let urlToUse = pageLocation || this.router.routerState.snapshot.url;

    if (pageLocation) {
      const [baseUrl, queryParams] = urlToUse.split('?');
      const { gender, status } = this.situationContext.situation;

      urlToUse = `${baseUrl}${this.slugify(
        `${queryParams ? `?${queryParams}&` : '?'}gender=${gender}&status=${status}`
      )}`;
    }

    if (pageLocation && pageTitle) {
      this.document.defaultView.history.pushState({}, pageTitle, urlToUse);
      this.document.title = pageTitle;
    } else {
      this.document.defaultView[this.DATA_LAYER_KEY].push({
        event: 'page_view',
        page_location: urlToUse,
        page_title: pageTitle || this.document.title,
        send_to: 'G-W2GY78TSN6'
      });
    }
  }

  public slugify(input: string): string {
    return input
      .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .replace(/[^\w\-&=?]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Squash -
      .replace(/^-+/g, '') // Remove starting -
      .replace(/-$/g, ''); // Remove trailing -
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  /**
   * Initialise the consent from the application storage
   *
   * @returns An observable that completes when the initialization is achieved
   */
  public init(): Observable<void> {
    this.document.defaultView[this.DATA_LAYER_KEY] =
      this.document.defaultView[this.DATA_LAYER_KEY] || [];

    if (sessionStorage.getItem(this.DISMISS_BANNER_STORAGE_KEY)) this.hasGivenChoice$.next(true);
    else if (
      localStorage.getItem(this.CONSENT_PRIVACY_POLICY_STORAGE_KEY) ===
      this.CONSENT_PRIVACY_POLICY_STORAGE_VALUE
    )
      return this.achieveConsent();

    return EMPTY;
  }

  /**
   * Set user consent for tracking and load the Google Tag Manager script and start tracking
   *
   * @returns An observable that completes when the consent is done
   */
  public consent(): Observable<void> {
    if (this.hasGivenChoice$.value) return EMPTY;

    localStorage.setItem(
      this.CONSENT_PRIVACY_POLICY_STORAGE_KEY,
      this.CONSENT_PRIVACY_POLICY_STORAGE_VALUE
    );
    sessionStorage.removeItem(this.DISMISS_BANNER_STORAGE_KEY);

    return this.achieveConsent();
  }

  public dismiss(): void {
    sessionStorage.setItem(this.DISMISS_BANNER_STORAGE_KEY, `${true}`);

    this.hasGivenChoice$.next(true);
  }

  /**
   * Send an Analytics event to Google Tag Manager
   * If the GTM script is not loaded, it stacks tags in the data layer so they are sent once the GTM script is loaded
   *
   * @param event The event to send
   */
  public pushTag(event: AnalyticsEvent): void {
    this.document.defaultView[this.DATA_LAYER_KEY].push({
      ...event,
      application: this.context.applicationName
    });
  }

  /**
   * Load the Google Tag Manager script and start tracking
   *
   * @returns An observable that completes when the script is loaded
   */
  private loadGTMScript(): Observable<void> {
    return this.scriptLoaderService.loadScript(this.GTM_SCRIPT_URL).pipe(
      tap(() => {
        this.document.defaultView[this.DATA_LAYER_KEY].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
      }),
      ignoreElements()
    );
  }

  /**
   * Turn on the user consent and load the GTM script
   *
   * @returns An observable that completes when the consent is achieved
   */
  private achieveConsent(): Observable<void> {
    return this.loadGTMScript().pipe(
      tap({
        complete: () => {
          this.hasGivenChoice$.next(true);
        }
      })
    );
  }
}
