import { Product } from '@models/context/product';

/**
 * A config object setting whether the user context can be saved to the session storage and the local storage
 *
 * @param product The product
 * @returns Whether the user context can be saved to the session storage and the local storage
 */
export const SAVE_USER_CONTEXT_CONFIG = (product: Product): boolean => {
  switch (product) {
    case Product.Show:
      return false;
    default:
      return true;
  }
};
