/**
 * Different waypoint types
 */
export enum WaypointType {
  diploma = 'diploma', // A diploma waypoint
  domain = 'domain', // A job or diploma domain (education, research, ...)
  diplomaType = 'diplomaType', // A diploma type (BTS, CAP, ...)
  job = 'job', // A job domain
  diplomaMajor = 'diplomaMajor', // A diploma field
  bridgeAfter = 'bridgeAfter',
  bridgeDuring = 'bridgeDuring',
  school = 'school',
  company = 'company'
}

/**
 * Map a waypoint type from the Hroads API to a waypoint type
 *
 * @param hroadsType The waypoint type from the Hroads API
 * @returns The waypoint type
 */
export const hroadsTypeToWaypointType = (hroadsType: string): WaypointType => {
  if (hroadsType === 'diploma_major') return WaypointType.diplomaMajor;

  return hroadsType as WaypointType;
};
