import { statusKeyLabel } from '@clients/adventure/bean/situation.helper';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { AdventureType } from '@models/adventure-type/adventure-type';
import { Definition } from '@models/definition/definition';
import { ApiInstance } from '@models/instance/instance.api';
import { Situation } from '@models/situation/situation';
import { WaypointType } from '@models/waypoint/waypoint-type';

export class Instance {
  public id: string;
  public date: Date;
  public waypoints: Waypoint[] = [];
  public endYear: number;

  /**
   * Whether the instance has been saved by the attached user
   */
  public saved: boolean;

  public get lastWaypoint(): Waypoint {
    return this.waypoints?.length ? this.waypoints[this.waypoints.length - 1] : null;
  }

  public get nextJobOrDiplomaWaypoint(): Waypoint {
    if (this.jobOrDiplomaWaypoints?.length) {
      return this.jobOrDiplomaWaypoints[1];
    }

    return null;
  }

  public get jobOrDiplomaWaypoints(): Waypoint[] {
    return this.waypoints?.length
      ? this.waypoints?.filter(
          wp =>
            WaypointType.domain !== wp.nomenclature && WaypointType.diplomaType !== wp.nomenclature
        )
      : [];
  }

  public get statusKeyLabel(): string {
    return statusKeyLabel(this.situation.status, this.situation.gender);
  }

  /**
   * An adventure instance
   *
   * @param situation The user situation
   * @param definition The definition from which the instance is created
   * @param userEmail The user email
   * @param type The adventure type
   */
  constructor(
    public situation: Situation,
    public definition: Definition,
    public userEmail: string,
    public type: AdventureType
  ) {}

  public static createFromInstance(instance: Instance): Instance {
    const newInstance = new Instance(
      instance.situation,
      instance.definition,
      instance.userEmail,
      instance.type
    );

    newInstance.date = new Date(instance.date.getTime() / 1000);
    newInstance.waypoints = instance.waypoints;
    newInstance.endYear = instance.endYear;
    newInstance.saved = instance.saved;
    newInstance.id = null;

    return newInstance;
  }

  /**
   * Build the Instance object from the Api Instance object
   *
   * @param apiInstance The Api Instance object
   * @returns The new Instance object
   */
  public static build(apiInstance: ApiInstance): Instance {
    if (!apiInstance) return null;

    const newInstance = new Instance(
      Situation.build(apiInstance.situation),
      Definition.build(apiInstance.definition),
      apiInstance.userEmail,
      apiInstance.type
    );

    newInstance.id = apiInstance.id;
    newInstance.date = new Date(apiInstance.date);
    newInstance.waypoints = apiInstance.waypoints;
    newInstance.endYear = apiInstance.endYear;
    newInstance.saved = apiInstance.saved;

    return newInstance;
  }

  /**
   * Get the Api Instance object from the Instance object
   *
   * @returns The corresponding Api Instance object
   */
  public toApi(): ApiInstance {
    return {
      id: this.id,
      date: this.date ? `${this.date.getTime()}` : undefined,
      waypoints: [...this.waypoints],
      endYear: this.endYear,
      situation: this.situation.toApi(),
      definition: this.definition.toApi(),
      userEmail: this.userEmail,
      saved: this.saved,
      type: this.type
    } as ApiInstance;
  }

  public isOrigin(waypoint: Waypoint): boolean {
    return this.definition.origin.id === waypoint.id;
  }
}
