import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PageTitleService implements OnDestroy {
  public appTitle$ = new BehaviorSubject<string>('');

  private destroy$: Subject<void> = new Subject<void>();
  private navigationTrigger: string;

  public get isPopState(): boolean {
    return this.navigationTrigger === 'popstate';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private translateService: TranslateService
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const titleKey = this.resolveTitle(this.route.snapshot);

        if (titleKey) {
          const label = this.translateService.instant(titleKey);

          this.title.setTitle(label);
          this.appTitle$.next(label);
        }
      } else if (event instanceof NavigationStart) this.navigationTrigger = event.navigationTrigger;
    });
    this.translateService.onLangChange
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const titleKey = this.resolveTitle(this.route.snapshot);

        if (titleKey) {
          const label = this.translateService.instant(titleKey);

          this.title.setTitle(label);
          this.appTitle$.next(label);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Force title overriding
   *
   * @param title The new title
   */
  public overrideTitle(title: string): void {
    this.title.setTitle(title);
    this.appTitle$.next(title);
  }

  /* -- PRIVATE -- */

  private resolveTitle(route: ActivatedRouteSnapshot): string {
    if (route.firstChild) return this.resolveTitle(route.firstChild);

    return route.data.title;
  }
}
