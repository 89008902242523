import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Waypoint } from '@clients/adventure/bean/waypoint';
import { PopupApiClient } from '@core/services/clients/popup-api.client';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PopupApiService {
  private static readonly USER_GUID_STRING = 'userId';

  public userId: string;

  constructor(private client: PopupApiClient) {}

  /**
   * Handle userId to call Popup API at the end of the user's navigation
   */
  public handleUserId(next: ActivatedRouteSnapshot): void {
    const userId: string = next.queryParams[PopupApiService.USER_GUID_STRING];

    if (userId) this.userId = userId;
  }

  /**
   * Send user information to popup external API
   *
   * @param waypoint The last waypoint of the user
   */
  public sendInformation(waypoint: Waypoint): Observable<void> {
    if (this.userId) return this.client.sendInformation(this.userId, waypoint);

    return of(null);
  }
}
