/**
 * All the Analytics events sent to Google Tag Manager
 */
export enum AnalyticsEventType {
  CostFilterApply = 'CostFilterApply',
  CursusFilterApply = 'CursusFilterApply',
  DiscoverAdventures = 'DiscoverAdventures',
  ExitMap = 'ExitMap',
  JobOrDiplomaSearch = 'JobOrDiplomaSearch',
  LangSwitch = 'LangSwitch',
  LinkedInLinkClick = 'LinkedInLinkClick',
  LocationFilterApply = 'LocationFilterApply',
  MapBackClick = 'MapBackClick',
  MapPoiClick = 'MapPoiClick',
  MapVillageOpen = 'MapVillageOpen',
  MillionroadsProductClick = 'MillionroadsProductClick',
  MoreDetailsVillageClick = 'MoreDetailsVillageClick',
  MoreDetailsVillageFromPoiClick = 'MoreDetailsVillageFromPoiClick',
  MoreDetailsVillageTabChange = 'MoreDetailsVillageTabChange',
  MoreDomainClick = 'MoreDomainClick',
  NoRecordsFilters = 'NoRecordsFilters',
  OnBoardingComplete = 'OnBoardingComplete',
  OnBoardingFinalize = 'OnBoardingFinalize',
  OnBoardingFinalizeBacSpecialty = 'OnBoardingFinalizeBacSpecialty',
  NavigationEnd = 'NavigationEnd',
  ResumeExpand = 'ResumeExpand',
  RoadmapFinalizeSave = 'RoadmapFinalizeSave',
  RoadmapSave = 'RoadmapSave',
  RoadmapTabChange = 'RoadmapTabChange',
  SchoolContact = 'SchoolContact',
  SelectAdventure = 'SelectAdventure',
  UncompletedAdventureRoadmapGenerate = 'UncompletedAdventureRoadmapGenerate',
  VillageChoose = 'VillageChoose'
}
